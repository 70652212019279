import { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router";
import { HorizontalFlex, InputSearch, TableListView, MultiButton, Switch, useNotificationsContext } from "tridonic-web-ui";
import accessTokenProvider from '../../../services/generic/session'
import { useTranslation } from "react-i18next";
import services from "../../../services/generic/services";
import CSMasterpage from "../../../components/CSMasterpage";

export default function AcceptCompaniesPage() {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [criticalError, setCriticalError] = useState('');
    const { addNotification } = useNotificationsContext();
    const [companies, setCompanies] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const history = useHistory()

    useEffect(() => {

        async function fetchIsTridonicAdmin(){
            const userResponse = await services.getUser({
                tokenProvider: accessTokenProvider
            })
            if (userResponse.success) {           
                if(userResponse.data.userRoles.find(role => role.roleId == 1)){
                    fetchCompanyData(true)
                }else{
                    history.push({ pathname: `/` })
                }
            } else {
                if (userResponse.data.isActionError) {
                    addNotification({
                        text: t(userResponse.data.message),
                        type: 'critical'
                    })
                } else {
                    //  setErrorTemplate(t(userResponse.data.message))
                }
            }
        }

        fetchIsTridonicAdmin()
    }, [])

    const fetchCompanyData = useCallback(async function(setLoading){
        setLoading ? setIsLoading(true) : null
        const responseCompanies = await services.getCompany({
            tokenProvider: accessTokenProvider
        })
        
        if (responseCompanies.success) {                
            const updatedCompanyList = responseCompanies.data.map((item, index) => ({
                title: item.name,
                id: item.companyId,
                detailActive: item.enabled,
                columns: [
                    item.accountHolder.email
                ],
                actions: [
                    {
                        title: 
                            <Switch
                                checked = {item.enabled}
                                onChange={function (checked, id, event) {toggleCompanyEnableStatus(id)}}
                                active={false}
                            />
                    }
                ]
            }));

            setCompanyList(updatedCompanyList);
            setCompanies(updatedCompanyList);
        } else {
            if (responseCompanies.data.isActionError) {
                addNotification({
                    text: t(responseCompanies.data.message),
                    type: 'critical'
                })
            } else {
                setCriticalError(responseCompanies.data)
            }
        }

        setLoading ? setIsLoading(false) : null
    }, []);

    async function toggleCompanyEnableStatus(item){
        if(item != ""){
            console.log(item)

            const response = await services.updateCompanyEnabledStatus({
                companyId: item.id,
                enableState: !item.detailActive,
                tokenProvider: accessTokenProvider
            })
            
            if (response.success) {     
                fetchCompanyData(false)
            } else {
                if (response.data.isActionError) {
                    addNotification({
                        text: t(response.data.message),
                        type: 'critical'
                    })
                } else {
                    setCriticalError(response.data)
                }
            }
        }
    }
    
    async function filterCompanyList(option){
        if(option.data.id == "active-only"){
            setCompanyList(companies.filter((item) => item.detailActive == true))
        }else{
            setCompanyList(companies)
        }
    }

    async function searchCompanyList(searchTerm){
        setCompanyList(companies.filter((item) => item.title.toUpperCase().includes(searchTerm.toUpperCase()) || item.columns[0].toUpperCase().includes(searchTerm.toUpperCase())))
    }

    async function resetPolicies(){

        addNotification({
            text: t('AcceptCompanies.Messages.UpdateNoticesOngoingMessage'),
            type: 'info',
            allowClose: false,
            duration: 20})

        const response = await services.resetTermsAndConditionsPolicies({
            tokenProvider: accessTokenProvider
        })
        
        if (response.success) { 
            addNotification({
                text: t('AcceptCompanies.Messages.FinishedUpdatingNoticesOngoingMessage'),
                type: 'success'
            })
        } else {
            if (response.data.isActionError) {
                addNotification({
                    text: t(response.data.message),
                    type: 'critical'
                })
            } else {
                setCriticalError(response.data)
            }
        }
    }

    return (
        <>
            <CSMasterpage
                criticalError={criticalError}
                title={t('AcceptCompanies.Title')}
                leadingAction={function () { resetPolicies() }}
                leadingActionTitle={t('AcceptCompanies.UpdateLegalNotices')}
            >
                <HorizontalFlex >
                    <MultiButton
                        useMarginBottom={true}
                        options={[  
                            {
                            text: t('AcceptCompanies.ListCompanies.ShowAll'),
                            id: "show-all"
                            },
                            {
                            text: t('AcceptCompanies.ListCompanies.ActiveOnly'),
                            id: "active-only"
                            }
                        ]}
                        singleSelection={true}
                        itemWidth="150px"
                        onChange={function (option, allOptions) { filterCompanyList(option) }}
                        active={false}
                    />
                    <InputSearch
                        id="companySearch"
                        useSearchButton={false}
                        value=""
                        width="45%"
                        placeholder= {t('AcceptCompanies.ListCompanies.Search')}
                        onValueChange={(e) => searchCompanyList(e)}
                    />
                </HorizontalFlex>
                <TableListView
                    loading={isLoading}
                    onAction={function (item, action, index, event) { toggleCompanyEnableStatus(item) }}
                    columns={{
                        dataColumns: [
                          {
                            title: t('AcceptCompanies.ListCompanies.Administrator'),
                            width: '35%'
                          }
                        ],
                        actions:[{title:""}],
                        titleTitle: t('AcceptCompanies.ListCompanies.Company'),
                        titleWidth: '65%'
                      }}                    
                    data={companyList}
                    active={false}
                ></TableListView>
            </CSMasterpage>
        </>
    )
}
