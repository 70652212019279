import { useTranslation } from "react-i18next";
import CSMasterpage from "../../../components/CSMasterpage";

export default function NotFoundPage() {
    const { t } = useTranslation();

    return (
        <CSMasterpage
            criticalError={{
                title: t('Common.Error.PageNotFound.Title'),
                message: t('Common.Error.PageNotFound.Message')
            }}
        >
        </CSMasterpage>
    )
}