
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import services from "../../../services/generic/services";
import { useNotificationsContext, Text, Masterpage } from "tridonic-web-ui";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { storeCurrentPath } from "../AuthCallback";
import { loginB2CRedirect } from "../../../components/AuthenticationController";
import ErrorTemplate from "../../../components/parts/ErrorTemplate";
import { loginRequestwithInvite } from "../../../config";

export default function ValidateInvitePage() {
    const { t, i18n } = useTranslation()
    const history = useHistory()
    const { inviteId, companyId } = useParams()
    const [isLoading, setIsLoading] = useState(false);
    const { notifications, removeNotification } = useNotificationsContext()
    const [errorTitle, setErrorTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        async function fetchValidateInvite() {
            setIsLoading(true)
            let response = await services.validateInvite({
                companyId: companyId,
                inviteId: inviteId
            });
            
            if (response.success && response?.data == true) {
                debugger
                if(!isAuthenticated && inProgress === InteractionStatus.None){
                    loginB2CRedirect(instance, loginRequestwithInvite);
                }else{
                    history.push({ pathname: `/invites/acceptvalid/${inviteId}/${companyId}` })
                }
            } else {
                switch (response?.data?.message) {
                    case 'Invite has expired.':
                        setErrorTitle('ValidateInvite.Error.Expired.Title');
                        setErrorMessage('ValidateInvite.Error.Expired.Message');
                        break;
                    case 'Invite has been cancelled.':
                        setErrorTitle('ValidateInvite.Error.Cancelled.Title');
                        setErrorMessage('ValidateInvite.Error.Cancelled.Message');
                        break;
                    case 'Invite has already been accepted.':
                        setErrorTitle('ValidateInvite.Error.Accepted.Title');
                        setErrorMessage('ValidateInvite.Error.Accepted.Message');
                        break;
                    case 'Invite not found.':
                        setErrorTitle('ValidateInvite.Error.NotFound.Title');
                        setErrorMessage('ValidateInvite.Error.NotFound.Message');
                        break;
                    default:
                        // Set default error title and message
                        setErrorTitle('Common.Error.Messages.DefaultError');
                        setErrorMessage('Common.Error.Messages.DefaultErrorDesc');
                        break;
                }
            }
            setIsLoading(false)
        }
        fetchValidateInvite()

    }, [])

    function handleWithLang(lng) {
        i18n.changeLanguage(lng);
    };

    function handleUserLanguageChange(lng) {
        setSessionLocaleByUser({ locale: lng, userId: user?.id});
        handleWithLang(lng);
    };


    const headerActions = [
        {
            icon: 'globe',
            options: [
                {
                    actionCallback: () => handleUserLanguageChange("en"),
                    disabled: false,
                    text: t('Header.Language.English'),
                    selected: i18n.language === 'en'
                },
                {
                    actionCallback: () => handleUserLanguageChange("de"),
                    disabled: false,
                    text: t('Header.Language.German'),
                    selected: i18n.language === 'de'
                },
            ]
        },

    ]

    return (
        <>
            <Masterpage
                headerActions={headerActions}
                headerActionsAlignIconLeft={false}
                showHeaderActions={true}
                showBreadCrumb={false}
                headerTitle={t('Header.Title')}
                notifications={notifications}
                notificationDismissCallback={(notification) => removeNotification(notification)}
            >                  
            
                <ErrorTemplate error={{title: t(errorTitle), message: t(errorMessage)}} ></ErrorTemplate>
            </Masterpage>
        </>
    )
}