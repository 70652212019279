import { useEffect, useState } from "react";
import { loginRequest } from "../../../config";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import Plans from "../Plan/Plans";
import { getSessionForgetPassword, removeSessionForgotPassword } from "../../../utils/forgotPasswordUtil";

export default function Protected() {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [redirectTriggered, setRedirectTriggered] = useState(false);

    useEffect(() => {
        if(getSessionForgetPassword()) {
            removeSessionForgotPassword();
            return;
        }
        if (!redirectTriggered && !isAuthenticated && inProgress === InteractionStatus.None) {
            setRedirectTriggered(true);
            instance.loginRedirect(loginRequest);
        }
    }, [isAuthenticated, inProgress, instance]);

    return (
        <>  
          {isAuthenticated?<Plans></Plans>:null} 
        </>
    )
}