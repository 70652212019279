import styled from '@emotion/styled'
import { default_height, getFontMD, getFontSM } from "tridonic-web-ui/dist/styles/classes";

export default function InputStyle(theme) {

    const {
        density_space_sm, density_space_xs, density_space_3xs, shape_radius_xs, shape_radius_sm, shape_radius_lg, density_space_xxs,
        color_ambient_09, color_ambient_08, color_ambient_07, color_ambient_06, color_ambient_05, color_brand_primary_04, color_ambient_02,
        color_ambient_04,
        color_brand_primary_03, color_brand_primary_base,
        color_critical_base } = theme


    const Container = styled.div`
        display: flex;
        flex-direction: column;
        width: ${props => props.width ? props.width : '100%'};
        text-align: left;
        margin-left: ${props => props.marginLeft ?? '0px'};
        margin-right: ${props => props.marginRight ?? '0px'};
        margin-top: ${props => props.marginTop ?? '0px'};
        margin-bottom: ${props => props.marginBottom ?? '0px'};
    `

    const TextInput = styled.input`

        /* for range types */
        margin: 0; 

        box-sizing: border-box;
        width: 100%;
        height: ${p => p.type === 'range' ? shape_radius_sm : default_height};
        padding-left: ${p => p.type === 'range' ? '0px' : density_space_sm};
        padding-right: ${p => p.type === 'range' ? '0px' : p.focus && p.allowClear ? default_height : density_space_sm};
        padding-top:${density_space_xs};
        padding-bottom:${density_space_xs};
        ${getFontMD(theme)};
        font-family: inherit;
        border-radius: ${shape_radius_sm};
        border: ${p => {
            if (p.type === 'range') {
                return 'none'
            } else {
                if (p.disabled) {
                    return `1px solid ${color_ambient_07}`
                } else {
                    if (p.hasError) {
                        return `1px solid ${color_critical_base}`
                    } else {
                        if (p.focus) {
                            return `1px solid ${color_ambient_02}`
                        } else {
                            return `1px solid ${p.borderColor ? p.borderColor : color_ambient_06}`
                        }
                    }
                }
            }
            //props.type === 'range' ? 'none' : props.disabled ? `1px solid ${color_ambient_07}` : `1px solid ${props.hasError ? color_critical_base : props.focus ? color_ambient_02 : color_ambient_06}`};
        }};
        color: ${props => (props.disabled ? color_ambient_05 : color_ambient_02)};
        background-color: ${props => (props.type === 'range' ? 'transparent' : props.disabled ? color_ambient_07 : color_ambient_09)};
        cursor: ${props => (props.disabled ? 'no-drop !important' : null)};

        ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${color_ambient_05};
        opacity: 1; /* Firefox */
        }
        ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${color_ambient_05};
        }

        :hover {
            border: ${p => {
            // props.type === 'range' ? 'none' : (props.disabled ? `1px solid ${color_ambient_07}` : `1px solid ${props.hasError ? color_critical_base : color_ambient_02}`)};
            if (p.type === 'range') {
                return 'none'
            } else {
                if (p.disabled) {
                    return `1px solid ${color_ambient_07}`
                } else {
                    if (p.hasError) {
                        return `1px solid ${color_critical_base}`
                    } else {
                        return `1px solid ${p.borderColorHover ? p.borderColorHover : color_ambient_02}`
                    }
                }
            }
        }};
        }

        :focus {
            outline: none;
            border:  ${p => {
                //p.type === 'range' ? '0px' : '1px'} solid ${props => props.type === 'range' ? 'transparent' : props.hasError ? color_critical_base : color_ambient_02};
                if (p.type === 'range') {
                    return 'none'
                } else {
                    if (p.disabled) {
                        return `1px solid ${color_ambient_07}`
                    } else {
                        if (p.hasError) {
                            return `1px solid ${color_critical_base}`
                        } else {
                            return `1px solid ${p.borderColorFocus ? p.borderColorFocus : color_ambient_02}`
                        }
                    }
                }
            }}
        }

        :focus::placeholder {
            color: transparent;
        }

        ::selection,
        -moz-selection {
            background: ${p => p.type === 'range' ? 'transparent' : color_brand_primary_04};
        }

        /* slider style for chrome */

        -webkit-appearance: none;

        ::-webkit-slider-runnable-track {
            -webkit-appearance: none;
            background: ${color_ambient_06};
            border-radius: ${shape_radius_xs};
            height: ${density_space_3xs};
            border: none;
            margin-top: 0px;
            outline: none;
        }

        :focus::-webkit-slider-runnable-track {
            -webkit-appearance: none;
            background: ${color_brand_primary_03};
        }

        ::-webkit-slider-thumb {
            -webkit-appearance: none;
            margin-top: -${density_space_xxs};
            height: ${density_space_sm};
            width: ${density_space_sm};
            background: ${color_ambient_09};
            border-radius: ${shape_radius_lg};
            border: solid 1px ${color_brand_primary_base};
        }

        :focus::-webkit-slider-thumb {
            -webkit-appearance: none;
            background: ${color_brand_primary_base};
        }


        /* slider style for mozzilla */

        ::-moz-range-track  {
            -webkit-appearance: none;
            background: ${color_ambient_06};
            border-radius: ${shape_radius_xs};
            height: ${density_space_3xs};
        }

        :focus::-moz-range-track {
            background: ${color_brand_primary_03};
        }

        ::-moz-range-thumb {
            margin-top: -${density_space_xxs};
            height: ${density_space_sm};
            width: ${density_space_sm};
            background: ${color_ambient_09};
            border-radius: ${shape_radius_lg};
            border: solid 1px ${color_brand_primary_base};
        }

        :focus::-moz-range-thumb {
            background: ${color_brand_primary_base};
        }

       
    `



    const Label = styled.label`
        width: 100%;
        display: block;
        ${getFontMD(theme)};
        padding-bottom: ${density_space_3xs};
        color: ${props => (props.disabled ? color_ambient_05 : color_ambient_02)};
        cursor: ${props => (props.disabled ? 'no-drop' : 'pointer')};
        `

    const HintContainer = styled.div`
        padding-top: ${density_space_3xs};
        color: ${color_ambient_05};
        ${getFontSM(theme)};
    `


    const ResetIcon = styled.span`
        position: absolute;
        top: 1px;
        right: 1px;
        width: 48px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px ${shape_radius_sm} ${shape_radius_sm} 0px;
        cursor: pointer;

        :hover {
            background-color: ${color_ambient_08};
        }
    `
    const InputWrapper = styled.div`
        position: relative;
        min-height: ${default_height};
        display: flex;
        align-items: ${p => p.type === 'range' ? 'flex-end' : 'center'};
    `

    const RangeValue = styled.div`
        position: absolute;
        top: 0;
        left: calc(${p => p.centerPercentage}% - ${p => (p.centerPercentage <= 6 ? p.centerOffset - 4 : p.centerOffset) + 50}px);
        width: 100px;
        text-align: center;
        ${getFontMD(theme)}
        color: ${color_ambient_04};
    `

    return { Container, TextInput, Label, HintContainer, ResetIcon, InputWrapper, RangeValue }
}
