import styled from "@emotion/styled";

export default function MyPermitionsStyle({ density_space_xl, density_space_md, density_space_xs}) {

    const Actions = styled.div`
        display: flex; 
        align-items: flex-end;
        gap: ${density_space_md};

        [role="button"]: first-of-type {
            margin-left: auto;
        }
    `

    const DeleteMyUserAccount = styled.div`
        padding-top: ${density_space_xl}
    `

    return { Actions, DeleteMyUserAccount }
}