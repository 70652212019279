import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// MSAL imports
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./config";
import { initReactI18next } from "react-i18next";
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const msalContainer = { msalInstance: null }

msalContainer.msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalContainer.msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalContainer.msalInstance.setActiveAccount(accounts[0]);
}

msalContainer.msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalContainer.msalInstance.setActiveAccount(account);
  }
});

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    load: 'languageOnly', // looks only for the language, not for country, i.e. 'en-US' will look for 'en' translation
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

ReactDOM.render(
  <React.StrictMode>
    <App pca={msalContainer.msalInstance} />
  </React.StrictMode>,
  document.getElementById('root')
);
