import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../config';

const SessionStorageKey = 'local-url';

export const loginB2CRedirect = (instance, customLoginRequest) => {
    
    storeCurrentPath();

    debugger
    instance.loginRedirect(customLoginRequest ? customLoginRequest : loginRequest);
};

export const loginB2CPopup = (instance, customLoginRequest) => {
    
    storeCurrentPath();

    debugger
    instance.loginPopup(customLoginRequest ? customLoginRequest : loginRequest);
};

export const storeCurrentPath = () => {
    if(!window.location.pathname.includes("auth-callback"))
        window.sessionStorage.setItem(SessionStorageKey, window.location.pathname);
};
  
export const getStoredPath = () => {
    return window.sessionStorage.getItem(SessionStorageKey);
};

export const clearStoredPath = () => {
    debugger
    window.sessionStorage.removeItem(SessionStorageKey);
};
