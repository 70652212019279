import styled from '@emotion/styled';
import {  useThemeContext } from "tridonic-web-ui";

export default function ListDataStyle() {

    const { theme } = useThemeContext()
    const { color_ambient_02, color_ambient_05 } = theme

    const ItemTitle = styled.span`
        display: flex;
        align-items: center;
        color: ${props => props.active ? color_ambient_02 : color_ambient_05};
    `
    return { ItemTitle }
}