import ListFeatures from "../components/ListFeatures";
import services from "../services/generic/services";

const features = {

    plans: async ({ setIsLoading, setPlans, addNotification, setCriticalError, t, isLoggdIn=false, tokenProvider }) => {
        setIsLoading(true)

        const response = !isLoggdIn ? (await services.getPlansAnonymous()): (await services.getPlans({tokenProvider: tokenProvider}))
        if (response.success) {
            const data = response?.data;
            setPlans(data?.plans.map((plan, index) => (
                {
                    active: true,
                    child: <ListFeatures data={features.feature({ plan: plan, t: t })} />,
                    id: plan.planId,
                    title: plan.name,
                })))
        } else {

            if (response?.data?.isActionError) {
                addNotification({
                    text: t(response.data.message),
                    type: 'critical'
                })
            } else {
                setCriticalError(response.data)
            }


        }
        setIsLoading(false)
    }, 
    planByPlanId: async ({ planId, setIsLoading, setPlans, addNotification, setCriticalError,  t , detailActionActive=true, 
        leadingActionActive , isLoggdIn=false,  tokenProvider }) => {
        setIsLoading(true)
        const response = !isLoggdIn? (await  services.getPlanByPlanIdAnonymous({ planId: planId })):( await services.getPlanByPlanId({ tokenProvider: tokenProvider ,planId: planId }))
        if (response.success) {

            const data = response?.data;
            setPlans(data?.plans.map((plan, index) => (
                { 
                    active: true,
                    detailActionActive: false,
                    leadingActionActive:leadingActionActive,
                    child: <ListFeatures data={featTest({ plan: plan, t: t })} />,
                    id: plan.planId,
                    title: plan.name,
                })))
           
          
        } else {
            if (response?.data?.isActionError) {
                addNotification({
                    text: t(response.data.message),
                    type: 'critical'
                })
            } else {
                setCriticalError(response.data)
            }
        }
        setIsLoading(false)
    },
    getFeatureValueTranslation: ({ featuresValues, featureName, t }) => {
       
        const count = featuresValues.find(fv => fv.featureName === featureName)
        if (count !== undefined) {
            return t('Plan.Features.' + count.featureName + "Count", { count: Number(count.value) });
        } else {
            return t('Plan.Features.' + featureName + "DefaultCount");
        }
    },
    feature: ({ plan, t }) => {
        const featursPlan = [
            {
                label: t('Plan.Features.FileCountMax'),
                value: features.getFeatureValueTranslation({ featuresValues: plan.featureValues, featureName: 'FileCountMax', t: t })
            },
            {
                label: t('Plan.Features.UserCountMax'),
                value: features.getFeatureValueTranslation({ featuresValues: plan.featureValues, featureName: 'UserCountMax', t: t })
            },
            // {
            //     label: t('Plan.Features.Connect'),
            //     value: getFeatureValueTranslation({ featuresValues: plan.featureValues, featureName: 'Connect' })
            // },
            // {
            //    label: t('Plan.Features.AppCountMax'),
            //    value: features.getFeatureValueTranslation({ featuresValues: plan.featureValues, featureName: 'AppCountMax', t: t })
            //}
            // {
            //     label: t('Plan.Features.LightBranding'),
            //     value: getFeatureValueTranslation({ featuresValues: plan.featureValues, featureName: 'LightBranding' })
            // },
            // {
            //     label: t('Plan.Features.Monitor'),
            //     value: getFeatureValueTranslation({ featuresValues: plan.featureValues, featureName: 'Monitor' })
            // }
        ]

        featursPlan.push({
            label: t('Plan.Features.TrialPeriod'),
            value: t('Plan.Features.TrialPeriodCount', { count: Number(plan.price?.trialDays) })
        })
        featursPlan.push({
            label: t('Plan.Features.Price'),
            value: plan.price?.cost + ' ' + plan.price?.currency + '/' + plan.price?.recurrence
        })
        return featursPlan;
    }
}
export default features

 function  featTest ({ plan, t })  {
    const featursPlan = [
        {
            label: t('Plan.Features.FileCountMax'),
            value: features.getFeatureValueTranslation({ featuresValues: plan.featureValues, featureName: 'FileCountMax'+ ":", t: t })
        },
        {
            label: t('Plan.Features.UserCountMax'),
            value: features.getFeatureValueTranslation({ featuresValues: plan.featureValues, featureName: 'UserCountMax'+ ":", t: t })
        },
        // {
        //     label: t('Plan.Features.Connect'),
        //     value: getFeatureValueTranslation({ featuresValues: plan.featureValues, featureName: 'Connect' })
        // },
        {
            label: t('Plan.Features.AppCountMax'),
            value: features.getFeatureValueTranslation({ featuresValues: plan.featureValues, featureName: 'AppCountMax'+ ":", t: t })
        }
        // {
        //     label: t('Plan.Features.LightBranding'),
        //     value: getFeatureValueTranslation({ featuresValues: plan.featureValues, featureName: 'LightBranding' })
        // },
        // {
        //     label: t('Plan.Features.Monitor'),
        //     value: getFeatureValueTranslation({ featuresValues: plan.featureValues, featureName: 'Monitor' })
        // }
    ]

    featursPlan.push({
        label: t('Plan.Features.TrialPeriod'),
        value: t('Plan.Features.TrialPeriodCount', { count: Number(plan.price?.trialDays) })
    })
    featursPlan.push({
        label: t('Plan.Features.Price'),
        value: plan.price?.cost + ' ' + plan.price?.currency + '/' + plan.price?.recurrence
    })
    return featursPlan;
}