import { trackException } from "../../utils/AppInsights";
const SERVER_URL = process.env.REACT_APP_SERVER_URL ? process.env.REACT_APP_SERVER_URL : 'https://localhost:44367/api'
const SERVER_API_VERSION = process.env.REACT_APP_SERVER_API_VERSION;

async function getHeaders(tokenProvider) {
    return {
        'Authorization': await tokenProvider.getAccessToken(),
        'accept': 'application/json',
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    };
}

async function getHeadersUpload(tokenProvider) {
    return {
        'Authorization': await tokenProvider.getAccessToken(),
        'Access-Control-Allow-Origin': '*'
    };
}

async function authenticatedHttpRequest({ httpVerb, tokenProvider, url, payload }) {
    const options = {
        method: httpVerb,
        headers: await getHeaders(tokenProvider)
    };
    if (payload) { 
        options.body = JSON.stringify(payload);
    }
     
    let urlWithVersion = createUrlWithApiVersion(url);
    try {

        let response = await fetch(`${SERVER_URL}${urlWithVersion}`, options)

        if (!response.ok) {
            let data = {}
            try {
                data = await response.json();
            } catch (e) {
                data = response
            }
            return { data: errorHandle(data), success: false };
        }
        // If the response type is no content, response.json will return an exception
        let data = response.status === 204 ? {} : await response.json();
        return { data: data, success: true };

    } catch (e) {
        return { data: errorHandle(e), success: false }
    }
}
async function authenticatedHttpRequestUPLOAD({ httpVerb, tokenProvider, url, payload }) {
    const options = {
        method: httpVerb,
        headers: await getHeadersUpload(tokenProvider)
    };
    if (payload) { 
        options.body = payload;
    }
     
    let urlWithVersion = createUrlWithApiVersion(url);
    try {

        let response = await fetch(`${SERVER_URL}${urlWithVersion}`, options)

        if (!response.ok) {
            let data = {}
            try {
                data = await response.json();
            } catch (e) {
                data = response
            }
            return { data: errorHandle(data), success: false };
        }
        // If the response type is no content, response.json will return an exception
        let data = response.status === 204 ? {} : await response.json();
        return { data: data, success: true };

    } catch (e) {
        return { data: errorHandle(e), success: false }
    }
}
async function authenticatedHttpRequestDOWNLOAD({ httpVerb, tokenProvider, url, filename }) {
    const options = {
        method: httpVerb,
        headers: await getHeadersUpload(tokenProvider)
    };
    
     
    let urlWithVersion = createUrlWithApiVersion(url);
    try {

        let response = await fetch(`${SERVER_URL}${urlWithVersion}`, options)

        await downloadFileFromStream(response, filename);

        if (!response.ok) {
            let data = {}
            try {
                data = await response.json();
            } catch (e) {
                data = response
            }
            return { data: errorHandle(data), success: false };
        }
        
        return {success: true };

    } catch (e) {
        return { data: errorHandle(e), success: false }
    }
}

async function downloadFileFromStream(response, filename) {
    
    const blob = await response.blob(); 
    // Create a temporary URL for the Blob
    const url = URL.createObjectURL(blob); 
  
    // Create an <a> element
    const a = document.createElement('a'); 
    a.href = url; 
    a.download = filename;
    a.style.display = 'none';
  
    // Simulate a click on the <a> element
    document.body.appendChild(a); 
    a.click(); 
  
    // Revoke the temporary URL
    document.body.removeChild(a); 
    URL.revokeObjectURL(url); 
  }

export const GET = async ({ tokenProvider, url }) => {
    return authenticatedHttpRequest({httpVerb: 'GET', tokenProvider: tokenProvider, url: url});
}

export const POST = async ({ tokenProvider, url, payload, formData}) => {
    return authenticatedHttpRequest({httpVerb: 'POST', tokenProvider: tokenProvider, url: url, payload: payload, formData:formData});
}

export const PUT = async ({ tokenProvider, url, payload = [] }) => {
    return authenticatedHttpRequest({httpVerb: 'PUT', tokenProvider: tokenProvider, url: url, payload: payload});
}

export const PATCH = async ({ tokenProvider, url, payload = [] }) => {
    return authenticatedHttpRequest({httpVerb: 'PATCH', tokenProvider: tokenProvider, url: url, payload: payload});
}

export const DELETE = async ({ tokenProvider, url }) => {
    return authenticatedHttpRequest({httpVerb: 'DELETE', tokenProvider: tokenProvider, url: url});
}

export const UPLOAD = async ({ tokenProvider, url, payload}) => {
    return authenticatedHttpRequestUPLOAD({httpVerb: 'POST', tokenProvider: tokenProvider, url: url, payload: payload});
}

export const DOWNLOAD = async ({ tokenProvider, url, filename}) => {
    return authenticatedHttpRequestDOWNLOAD({httpVerb: 'GET', tokenProvider: tokenProvider, url: url, filename: filename});
}



export const GETAnonymous = async ({ url }) => {
    const options = {
        method: "GET",
        headers: {
            'accept': 'application/json',
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    url = createUrlWithApiVersion(url);
    try {

        let response = await fetch(SERVER_URL + url, options)

        if (!response.ok) {
            let data = {}
            try {
                data = await response.json();
            } catch (e) {
                data = response
            }
            return { data: errorHandle(data), success: false };
        }
        let data = await response.json();
        return { data: data, success: true };

    } catch (e) {
        return { data: errorHandle(e), success: false }
    }
}

export const errorHandle = (error) => {
    let err = {
        isActionError: true,
        status: undefined,
        action: "PUSH_NOTIFICATION",
        title: "",
        message: ""
    }
    try {
        if (error.status === undefined) {
            err.isActionError = false
            err.message = "Common.Error.Messages.ServerOffDesc"
            err.title=  "Common.Error.Messages.ServerOff"
            err.action = "NO_ACTION"
            return err
        } else {
            err.status = error.status;
            err.message = error.title;
            err.detail = error.detail;
            if (error.status === 401) {
                err.title = "Common.Error.Messages.Unauthorized"
                err.message = "Common.Error.Messages.UnauthorizedDesc";
                err.action= "DO_LOGOUT"
            } else if (error.status === 403) {
                err.title = "Common.Error.Messages.Forbidden"
                err.message = "Common.Error.Messages.ForbiddenDesc";
                err.action= "ERROR_PAGE"
            } else if (error.status === 406) {
                err.title = "Common.Error.Messages.NotSupportedTitle"
                err.message = "Common.Error.Messages.NotSupportedDesc";
                err.action= "ERROR_PAGE"
            } else if (error.status === 400 || error.status === 404 || error.status === 409) {
                // ignore 
            }
            else if (error.status >= 400 && error.status < 500) {
                err.title = "Common.Error.Messages.RequestError"
                err.message = "Common.Error.Messages.RequestError";
                err.action= "ERROR_PAGE"
            } else if (error.status >= 500) {
                err.title = "Common.Error.Messages.InternalServerError"
                err.message = "Common.Error.Messages.InternalServerDesc";
                err.action= "ERROR_PAGE"
            } else {
                err.title = "Common.Error.Messages.DefaultError"
                err.message = "Common.Error.Messages.DefaultErrorDesc";
                err.action= "ERROR_PAGE"
            }
        }
    } catch (e) {
        err.isActionError = false
        err.title = "Common.Error.Messages.ServerOff"
        err.message = "Common.Error.Messages.ServerOffDesc"
        err.action= "ERROR_PAGE"
    }
    trackException({ exception : error });
    return err;
};

/**
 * Returns a new URL with the api-version query parameter appended to the requested URL
 * return {string} new URL
 */
function createUrlWithApiVersion(url) {
    let hasQueryParams = url.match(/\?./);
    let separator = hasQueryParams ? '&' : '?';
    return `${url}${separator}api-version=${SERVER_API_VERSION}`
}