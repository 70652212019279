import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import accessTokenProvider from '../../../../services/generic/session'
import services from "../../../../services/generic/services";
import permition from '../../../../services/generic/permition'
import { useNotificationsContext } from "tridonic-web-ui";
import CompanyTabs from "../../../../enum/CompanyTabs";
import CompanyMasterpage from "../../../../components/CompanyMasterpage";
import PremiumFeatures from "./PremiumFeatures";

export default function PremiumFeaturesTabPage() {

    const location = useLocation();
    const companyNameParam = location.state?.companyName;

    const [isLoading, setIsLoading] = useState(false);
    const [companyName, setCompanyName] = useState("");
    const [criticalError, setCriticalError] = useState('')
    const [userTabVisible, setUserTabVisible] = useState(false);
    const [companyData, setCompanyData] = useState([])
    const [invites, setInvites] = useState([])
    const { companyId } = useParams()
    const { addNotification } = useNotificationsContext()
    const { t } = useTranslation();
    const [loadDataVersion, setLoadDataVersion] = useState(null)
    const [isAdmin, setIsAdmin] = useState(false);
    const [isAccountHolder, setIsAccountHolder] = useState(false);

    useEffect(() => {
        
        async function fetchData() {       
            setIsLoading(true)

            // Set if user Is admin To this company 
            const userResponse = await services.getUser({
                tokenProvider: accessTokenProvider
            })
            if (userResponse.success) {           
                setIsAdmin( await permition.isAdminInCompany(
                    {
                        companies: userResponse.data.companies,
                        companyId: companyId
                    }))
            } else {

                if (userResponse.data.isActionError) {
                    addNotification({
                        text: t(userResponse.data.message),
                        type: 'critical'
                    })
                } else {
                    //  setErrorTemplate(t(userResponse.data.message))
                }
            }


            // get Companydata
            const response = await services.getCompanyByCompanyId({
                tokenProvider: accessTokenProvider,
                companyId: companyId
            })

            if (response.success) {
                const data = response.data;

                setCompanyName(data.name)
                setCompanyData(data)
                setIsAccountHolder(permition.isCompanyAccountHolder({company: data, userId: userResponse.data.id}));    
                setIsLoading(false)
            } else {
                if (response.data.isActionError) {

                    if (response.data.status === 404) {
                        response.data.message = 'Account.Message.NotFound'
                        setCriticalError(response.data)
                        setIsLoading(false)
                        return
                    }
                    setCriticalError(response.data)
                } else {
                    setCriticalError(response.data)
                }
            }

            const resp = await services.getInvitesByCompany({
                tokenProvider: accessTokenProvider,
                companyId
            });
            if (resp.success) {
                setInvites(resp.data);

            } else {
                if (resp.data.isActionError) {
                    // if 403 the user can not see list invites listed

                    if (resp.data.status !== 403) {
                        addNotification({
                            text: t(resp?.data?.message),
                            type: 'critical'
                        })
                    }
                } else {
                    setCriticalError(response.data)
                }
            }
            setUserTabVisible(true)
            setIsLoading(false)
        }
        fetchData()

    }, [loadDataVersion])


    //setLoadDataVersion(x => x+1)

    return (
            <CompanyMasterpage 
                loading={isLoading}
                title={companyName ? companyName: companyNameParam}
                companyId={companyId}
                companyEnable={companyData.enabled}
                tabsEnabled={true}
                tabSelectedIndex={CompanyTabs.PremiumFeatures}
                criticalError={criticalError}
                isAccountHolder={isAccountHolder}
                isAdmin={isAdmin}
            >
            {
                    <PremiumFeatures
                        isAccountHolder = {isAccountHolder}
                        isAdmin = {isAdmin}
                        companyData={companyData}
                        invites={invites}
                        isLoading={isLoading}
                        setIsLoading={(value) => setIsLoading(value)}
                        setCriticalError={setCriticalError} loadDataCallback={() => setLoadDataVersion(x => x===0 ? 0 : x + 1)}>
                    </PremiumFeatures>
                        
                }
                
            </CompanyMasterpage>

    )
}

