
import { useState } from "react";
import {
    color_critical_strong,
    Overlay,
    useNotificationsContext,
} from "tridonic-web-ui";
import accessTokenProvider from '../../../../services/generic/session'
import { useTranslation } from "react-i18next";
import services from "../../../../services/generic/services";
import DeleteConfirmation from "../../../../components/parts/DeleteConfimation";
import { useHistory } from "react-router";
import notificationsHelper from "../../../../utils/notificationsHelper";

export default function DeletePremiumAccount({ onClose, overlayVisible , companyData  }) {

    const { addNotification } = useNotificationsContext()
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const history = useHistory()
    const [notificationsDeleteMyUserAccount, setNotificationsDeleteMyUserAccount] = useState('')
    const [notificationsDeleteMyUserAccountType, setNotificationsDeleteMyUserAccountType] = useState('')
   
    async function deleteAccountAction() {
        setIsLoading(true)
        const response = await services.deleteCompany({
            tokenProvider: accessTokenProvider,
            companyId: companyData?.companyId
        });

        if (response.success) {
            setNotificationsDeleteMyUserAccount(t('Account.Company.DeletePremiumAccountSection.Message.Success'))
            setNotificationsDeleteMyUserAccountType('success')
            addNotification(notificationsHelper.success({text:t('Account.Company.DeletePremiumAccountSection.Message.Success')}))
            history.push({ pathname: '/' })
    
    
        } else {
            if (response.data.isActionError) {
                if (response?.data?.status === 400) {
                    setNotificationsDeleteMyUserAccount(t('MyPermitions.DeleteMyAccountUser.Message.' + response?.data?.message))
                    setNotificationsDeleteMyUserAccountType('error')
                } else {
                    setNotificationsDeleteMyUserAccount(t(response?.data?.message))
                    setNotificationsDeleteMyUserAccountType('error')
                }
            } else {
              //  setErrorTemplate(t(response.data.message))
            }
        }
        setIsLoading(false)
    }

    return (
        <>
    
            <Overlay
                actions={[
                    {
                        action: function () { deleteAccountAction() },
                        title: t('Common.Actions.Delete'),
                        type: 'ghost_semantic',
                        color: { color_critical_strong }
                    },
                    {
                        action: function () {  onClose();},
                        title: t('Common.Actions.Cancel'),
                        type: 'ghost'
                    }
                ]}
                centerContentVertically={true}
                error={false}
                height={null}
                loading={isLoading}
                notifications={notificationsDeleteMyUserAccount}
                notificationsType={notificationsDeleteMyUserAccountType}
                title={t('Account.Company.DeletePremiumAccountSection.Title')}
                visible={overlayVisible}
            >
                <DeleteConfirmation
                    question={t('Account.Company.DeletePremiumAccountSection.Question')}
                    warning={t('Account.Company.DeletePremiumAccountSection.Warning')}
                >
                </DeleteConfirmation>
            </Overlay>

          
        </>
    )
}
