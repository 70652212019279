import styled from '@emotion/styled';
import { xs, md } from 'tridonic-web-ui/dist/styles/media-queries';

export default function StorageTabPageStyle(theme) {

    const EmptyDiv = styled.div`
        ${xs} {
            display: none;
        }

        ${md} {
            display: none;
        }

    `
    return { EmptyDiv }
}