import { msalContainer } from "../..";
import { loginRequestwithScope, claims } from "../../config";
import { trackException } from '../../utils/AppInsights';

const session = {

    getAccessToken: async () => {

        let accounts = msalContainer.msalInstance.getAllAccounts();
        let account = accounts.filter(x => x.idTokenClaims?.tfp?.toLowerCase() === claims.signUpSignIn)[0];
        let request = { scopes: loginRequestwithScope.scopes, account: account || {}, redirectUri: window.location.href }
     
        try {
            const response = await msalContainer.msalInstance.acquireTokenSilent(request);

            return `Bearer ${response.accessToken}`;

        } catch (error) {

            if (JSON.stringify(error).indexOf("AADB2C90077")) {

                try {
                    const accessTokenResponse = await msalContainer.msalInstance.acquireTokenRedirect(request)
                    return `Bearer ${accessTokenResponse.accessToken}`;
                } catch (e) {
                    msalContainer.msalInstance.logout();
                }

            }
            console.error(error)
            trackException({ exception: error });
        }
    }
}

export default session
