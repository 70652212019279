import styled from "@emotion/styled";
import { xs, md } from "tridonic-web-ui";

export default function NewAccountPageStyle({ density_space_xs, density_space_md, density_space_lg, density_space_xl, color_brand_primary_base, color_brand_primary_01 }) {

    const Link = styled.a`
        text-decoration: none;

        /* unvisited link */
        :link {
            color: ${color_brand_primary_base};
        }

        /* visited link */
        :visited {
            color: ${color_brand_primary_01};
        }
    `

    const Plan = styled.div`
        padding-top: ${density_space_md};
        
    `

    const LableTerms = styled.div`
        padding-top: ${density_space_md};
    `
    
    const CheckBoxPadding = styled.div`
        padding: ${density_space_xs};
        padding-top: ${density_space_md};
    `

    const NewAccount = styled.div`
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        column-gap: ${density_space_lg};
        row-gap: ${density_space_lg};
    `

    const Left  = styled.div`
        flex-shrink: 0;
        max-width: 400px;

        ${xs} {
            max-width: 100%;
        }
    `

    const Action = styled.div`
        padding-top: ${density_space_xl};
        color: ${color_brand_primary_base};
    `

    const Right = styled.div`
        flex-grow: 1;
    `

    const EmptyDiv = styled.div`
    ${xs} {
        display: none;
    }

    ${md} {
        display: none;
    }

`
    return { Action, Right, Left, NewAccount, CheckBoxPadding, LableTerms, Plan, Link, EmptyDiv }
}
