import { EventType, InteractionType, InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { Switch, Route } from "react-router";
import { BrowserRouter, Redirect } from "react-router-dom";
import { forgotPasswordRequest, claims, logoutRedirectUri } from "../config";
import NotFoundPage from "./generic/NotFoundPage/NotFoundPage";
import ForgetPasswordPageFallback from "./generic/ForgetPasswordPageFallback/ForgetPasswordPageFallback";
import Plans from "./generic/Plan/Plans";
import NewAccountPage from "./generic/NewAccountPage/NewAccountPage";
import Protected from "./generic/Protected/Protected";
import PlansAuthPage from "./generic/PlansAuthPage/PlansAuthPage";
import HomePage from "./generic/HomePage/HomePage";
import AcceptInvitePage from "./generic/AcceptInvitePage/AcceptInvitePage";
import UserTabPage from "./generic/AccountPage/Users/UserTabPage";
import PaddleCheckout from "./generic/NewAccountPage/PaddleCheckout";
import CompanyTabPage from "./generic/AccountPage/Company/CompanyTabPage";
import NewAccountLegalPage from "./generic/NewAccountPage/NewAccountLegalPage";
import SettingsTabPage from "./generic/AccountPage/Settings/SettingsTabPage";
import { setSessionForgotPassword } from "../utils/forgotPasswordUtil";
import PremiumFeaturesTabPage from "./generic/AccountPage/PremiumFeatures/PremiumFeaturesTabPage";
import StorageTabPage from "./generic/AccountPage/Storage/StorageTabPage";
import ValidateInvitePage from "./generic/AcceptInvitePage/ValidateInvitePage";
import AcceptCompaniesPage from "./generic/AcceptCompaniesPage/AcceptCompaniesPage";
import services from "../services/generic/services";
import accessTokenProvider from '../services/generic/session'
import AuthCallback from "./generic/AuthCallback";
import { loginB2CPopup, loginB2CRedirect } from "../components/AuthenticationController";

export const HOME_PAGE_PATH = '/';

export default function Pages() {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        const callbackId = instance.addEventCallback(async (event) => {
            if (event.eventType === EventType.LOGIN_FAILURE) {
                if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
                    if (event.interactionType === InteractionType.Redirect) {
                        setSessionForgotPassword();
                        loginB2CRedirect(instance, forgotPasswordRequest);
                    } else if (event.interactionType === InteractionType.Popup) {
                        loginB2CPopup(instance, forgotPasswordRequest).catch(e => {
                            return;
                        });
                    }
                } 
                    if (event.error && event.error.errorMessage.indexOf("AADB2C90077") > -1) {                       
                        instance.logoutRedirect();
                        if (event.interactionType === InteractionType.Redirect) {
                            loginB2CRedirect(instance, forgotPasswordRequest);
                        } else if (event.interactionType === InteractionType.Popup) {
                            loginB2CPopup(instance, forgotPasswordRequest).catch(e => {
                                return;
                            });
                        }                   
                }
            } else if (event.eventType === EventType.LOGIN_SUCCESS) {
                const response = await services.getUser({
                    tokenProvider: accessTokenProvider
                })
                if(response.data.active == false) {
                    instance.logoutRedirect();
                }
                if (event.payload.idTokenClaims["iat"] === claims.forgotPassword) {
                    // Tokens returned from password reset policy cannot be used for sign-in policy, must log out then sign back in
                    instance.logout({
                        account: event.payload.account
                    });
                }
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
    }, []);

    if (!isAuthenticated) {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/plans" component={Plans} exact />
                    <Route path="/new-account/plan/:planId" component={Protected} exact />
                    <Route path="/invites/accept/:inviteId/:companyId" component={ValidateInvitePage} exact />
                    {inProgress === InteractionStatus.None ? <Route path={HOME_PAGE_PATH} component={Protected} exact /> : null }
                    {inProgress === InteractionStatus.None ? <Redirect from='*' to={HOME_PAGE_PATH} /> : null}
                </Switch>
            </BrowserRouter>
        )
    } else {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/plans-auth" component={PlansAuthPage} exact />
                    <Route path='/new-account' component={NewAccountPage} exact />
                    <Route path='/new-account/plan/:planId' component={NewAccountPage} exact />
                    <Route path='/new-account/legal' component={NewAccountLegalPage} exact />
                    <Route path='/new-account/checkout' component={PaddleCheckout} exact />
                    <Route path='/account/:companyId/premiumFeatures' component={PremiumFeaturesTabPage} exact />
                    <Route path='/account/:companyId/user' component={UserTabPage} exact />
                    <Route path='/account/:companyId/company' component={CompanyTabPage} exact />
                    <Route path='/account/:companyId/settings' component={SettingsTabPage} exact />
					<Route path='/account/:companyId/storage' component={StorageTabPage} exact />
                    <Route path="/invites/accept/:inviteId/:companyId" component={ValidateInvitePage} exact />
                    <Route path="/invites/acceptvalid/:inviteId/:companyId" component={AcceptInvitePage} exact />
                    <Route path="/forgetPasswordPageFallback" component={ForgetPasswordPageFallback} exact />
                    <Route path="/acceptcompanies" component={AcceptCompaniesPage} exact />
                    <Route path="/auth-callback" component={AuthCallback} exact />
                    <Route path={HOME_PAGE_PATH} component={HomePage} exact />
                    <Route path='*' component={NotFoundPage} />

                </Switch>
            </BrowserRouter>
        )
    }
}