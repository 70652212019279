import services from "../../../../services/generic/services";
import notificationsHelper from "../../../../utils/notificationsHelper";
import { useRef,useEffect,useState } from "react";
import accessTokenProvider from '../../../../services/generic/session'
import { useTranslation } from "react-i18next";
import { Overlay, useNotificationsContext } from "tridonic-web-ui";

export default function StorageUploadOverlay({ overlayData, setCriticalError, onClose, onSuccessCallback }) {

    const { t } = useTranslation();
    const { addNotification } = useNotificationsContext()
    const [notifications, setNotifications] = useState('')
    const [notificationsType, setNotificationsType] = useState('')
    const [overlayVisible, setOverlayVisible] = useState(true)
    const inputFile = useRef(null)

    useEffect(() => {
        
        setNotifications('')
        setNotificationsType('')

    }, [])

    function getFileData(){
        var file = inputFile.current.files[0];

        let formData = new FormData();
        formData.append("name", file.name);
        formData.append("file", file);

        return formData;
    }

    async function postServer() {

        let response;
        
        if(overlayData.parentFolderId == undefined){

            response = await services.uploadToRootFile({
                tokenProvider: accessTokenProvider,
                companyId: overlayData.companyId,
                data: getFileData()
            });

        } else {

            response = await services.uploadFile({
                tokenProvider: accessTokenProvider,
                companyId: overlayData.companyId,
                parentFolderId: overlayData.parentFolderId,
                data: getFileData()
            });

        }


        if (response.success) {

            addNotification(notificationsHelper.success({text:t(`Account.OnlineStorage.UploadModal.Success`)}))
           
            onSuccessCallback(overlayData);

            closeOverlay();

        } else {
            if (response.data.isActionError) {
                    setNotifications(t(response?.data?.message))
                    setNotificationsType('error')
            } else {
                setCriticalError(response.data)
            }
        }
    }

    function closeOverlay() {
         setOverlayVisible(false);
         onClose();
    }

    return (
        <>

            <Overlay
                actions={[
                    {
                        action: function () { postServer() },
                        title: t(`Account.OnlineStorage.UploadModal.ActionButton`),
                        type: 'primary',
                    },
                    {
                        action: function () { closeOverlay(); },  
                        title: t('Account.OnlineStorage.CommonModal.ActionCancel'),
                        type: 'ghost'
                    }
                ]}
                allowCloseWithActions={false}
                centerContentVertically={true}
                error={false}
                errorTemplate={ t('Account.OnlineStorage.GenericError')}
                height={null}
                loading={false}
                notifications={notifications}
                notificationsType={notificationsType}
                title={ t(`Account.OnlineStorage.UploadModal.Title`)}
                visible={overlayVisible}
            >
                <>
                    <div>
                        <form>
                           <div>
                                <input type="file"  ref={inputFile} accept=".trgf,.traf"></input> 
                            </div>
                        </form>
                    </div>
                </>
            </Overlay>
        </>
    )
}