
import { notificationConfig } from "../config";

const notificationsHelper = {
    success:  ({ text }) => {
       return {
            text: text,
            type: 'success',
            durantion: notificationConfig.success.duration,
            allowClose: notificationConfig.success.allowClose
        }
    },
    critical:  ({ text }) => {
        return {
             text: text,
             type: 'critical',
             durantion: notificationConfig.success.duration,
             allowClose: notificationConfig.success.allowClose
         }
     } 

   
}
export default notificationsHelper
