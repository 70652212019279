import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Masterpage, color_ambient_05,useNotificationsContext } from "tridonic-web-ui";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useHistory } from "react-router";
import MyPermitions from "./MyPermitions";
import ErrorTemplate from "./parts/ErrorTemplate";
import services from "../services/generic/services";
import accessTokenProvider from '../services/generic/session'
import { logoutRedirectUri } from "../config";
import { getSessionLocaleByUser, setSessionLocaleByUser } from '../utils/localizationUtils';


export default function CSMasterpage({
    breadcrumb = null,
    footerActions = null,
    headerActions = null,
    leadingAction = null,
    leadingActionIcon = null,
    leadingActionTitle = null,
    loading,
    title,
    titleNote,
    trailingAction = null,
    trailingActionIcon = null,
    trailingActionTitle = null,
    tabClickedCallback = null,
    tabWidth = "224",
    tabs = null,
    children,
    criticalError

}) {
    const { t, i18n } = useTranslation()
    const { instance } = useMsal()
    const [overlayVisible, setOverlayVisible] = useState(false)
    const { notifications, removeNotification } = useNotificationsContext()
    const history = useHistory()
    const isAuthenticated = useIsAuthenticated();
    const [user, setUser] = useState(null);
    const [showAcceptCompaniesOption, setShowAcceptCompaniesOption] = useState(false);

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );
      
    useEffect(() => {
        if (isAuthenticated) {
            async function fetchUserData() {
                const response = await services.getUser({
                    tokenProvider: accessTokenProvider
                })
                if (response.success) {
                    setUser(response.data);
                    let currentUILocale = getSessionLocaleByUser({userId: response.data?.id});
                    handleWithLang(currentUILocale ? currentUILocale :  response.data?.language);

                    if(response.data.userRoles.find(role => role.roleId == 1)){
                        setShowAcceptCompaniesOption(true);
                    }
                }   
            }

            fetchUserData()
        }
    }, [isAuthenticated])

    function handleLogout() {
        debugger
        instance.logoutRedirect({
            postLogoutRedirectUri: logoutRedirectUri
        });
    }

    function handleShowPrivacyPolicy() {
        UC_UI.showSecondLayer();
    }

    function handleWithLang(lng) {
        i18n.changeLanguage(lng);
    };

    function handleUserLanguageChange(lng) {
        setSessionLocaleByUser({ locale: lng, userId: user?.id});
        handleWithLang(lng);
    };

    const headerActionDefault = [
        {
            icon: 'home',
            actionCallback: function () { history.push({ pathname: '/' })}
        },
        {
            icon: 'user',
            options: [
                {
                    actionCallback: () => history.push({ pathname: '/' }),
                    disabled: false,
                    text: t('Header.User.PremiumAccounts'),
                    icon: 'invalid'
                },
                {
                    actionCallback: () => setOverlayVisible(true),
                    disabled: false,
                    text: t('Header.User.MyPermitions'),
                    icon: 'invalid'
                },
                {
                    actionCallback: () => handleShowPrivacyPolicy(),
                    disabled: false,
                    text: t('Header.User.PrivacyPolicy')
                },
                {
                    actionCallback: () => handleLogout(),
                    disabled: false,
                    text: t('Header.User.Logout'),
                    icon: 'logout'
                },
            ]
        },
        {
            icon: 'globe',
            options: [
                {
                    actionCallback: () => handleUserLanguageChange("en"),
                    disabled: false,
                    text: t('Header.Language.English'),
                    selected: i18n.language === 'en'
                },
                {
                    actionCallback: () => handleUserLanguageChange("de"),
                    disabled: false,
                    text: t('Header.Language.German'),
                    selected: i18n.language === 'de'
                },
            ]
        },

    ]
    const adminHeaderActionDefault = [
        {
            icon: 'home',
            actionCallback: function () { history.push({ pathname: '/' })}
        },
        {
            icon: 'user',
            options: [
                {
                    actionCallback: () => history.push({ pathname: '/' }),
                    disabled: false,
                    text: t('Header.User.PremiumAccounts'),
                    icon: 'invalid'
                },
                {
                    actionCallback: () => setOverlayVisible(true),
                    disabled: false,
                    text: t('Header.User.MyPermitions'),
                    icon: 'invalid'
                },
                {
                    actionCallback: () => history.push({ pathname: '/acceptcompanies' }),
                    disabled: false,
                    text: t('Header.User.AcceptCompanies'),
                    icon: 'collaboration'
                },
                {
                    actionCallback: () => handleShowPrivacyPolicy(),
                    disabled: false,
                    text: t('Header.User.PrivacyPolicy')
                },
                {
                    actionCallback: () => handleLogout(),
                    disabled: false,
                    text: t('Header.User.Logout'),
                    icon: 'logout'
                }
            ]
        },
        {
            icon: 'globe',
            options: [
                {
                    actionCallback: () => handleUserLanguageChange("en"),
                    disabled: false,
                    text: t('Header.Language.English'),
                    selected: i18n.language === 'en'
                },
                {
                    actionCallback: () => handleUserLanguageChange("de"),
                    disabled: false,
                    text: t('Header.Language.German'),
                    selected: i18n.language === 'de'
                },
            ]
        },

    ]

    function iconPosition(){
       
        let betaStyle = {position: "fixed", zIndex: "1", marginLeft: "320px", top: "20px"}

        return betaStyle;
    }
    
    function betaIcon(){
        let iconStyle = {
            position: "fixed",
            background: "#CC9C00",
            textAlign: "center",
            borderRadius: "10px",
            color: "white",
            padding: "0px 6px 0px 6px",
            fontSize: "10px",
            lineHeight: "13px",
            marginLeft: "2px",
            userSelect: "none"
        }

        return iconStyle;
    }

    return (
        <>
            
             {criticalError ==='' || criticalError ===undefined || criticalError ===null  ?
                <Masterpage
                    breadcrumb={breadcrumb}
                    canClickSelectedTab={false}
                    collapseActionsToHeader={false}
                    footerActions={footerActions}
                    headerActions={headerActions !== null ? headerActions : showAcceptCompaniesOption ? adminHeaderActionDefault: headerActionDefault}
                    showBreadCrumb={breadcrumb !== null}
                    headerActionsAlignIconLeft={false}
                    headerTitle={t('Header.Title')}
                    leadingAction={leadingAction}
                    leadingActionIcon={leadingActionIcon}
                    leadingActionIconColor={color_ambient_05}
                    leadingActionTitle={leadingActionTitle}
                    loading={loading}
                    showHeaderActions={true}
                    tabClickedCallback={(tab, index) => tabClickedCallback({ selectTab: tab })}
                    tabWidth={tabWidth}
                    tabs={tabs}
                    title={title}
                    titleNote={titleNote}
                    trailingAction={trailingAction}
                    trailingActionIcon={trailingActionIcon}
                    trailingActionTitle={trailingActionTitle}
                    notifications={notifications}
                    notificationDismissCallback={(notification) => removeNotification(notification)} 
                    showFooterPadding={true}
                    // footerActions={footerActions}
                    style={{position: "absolute", zIndex: "-1"}}         
                >   
                    <div style={iconPosition()}>
                        <span style={betaIcon()}>&lt;/&gt;BETA</span>
                    </div> 
                    <div>
                        {children}
                    </div>

                </Masterpage>:
                <Masterpage
                    headerActions={headerActions !== null ? headerActions : headerActionDefault}
                    headerActionsAlignIconLeft={false}
                    showBreadCrumb={false}
                    headerTitle={t('Header.Title')}
                    showHeaderActions={true}
                    notifications={notifications}
                    notificationDismissCallback={(notification) => removeNotification(notification)}
                >                  
                
                    <ErrorTemplate error={criticalError} ></ErrorTemplate>
                </Masterpage>
                
            }

            {overlayVisible ? 
                <MyPermitions onClose={() => setOverlayVisible(false)}/>
                : null
            }    
        </>
    )
}

