import { useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next"
import CSMasterpage from "../../../components/CSMasterpage";
import PlansFeatures from "../../../components/PlansFeatures";
import accessTokenProvider from '../../../services/generic/session'
export default function PlansAuthPage() {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const history = useHistory()

    function goToNewAccountPage ({ item }) {
        history.push({ pathname: '/new-account/plan/' + item.id })
    }

    return (
        <>
            <CSMasterpage
                // breadcrumb={[
                //     {
                //         pathname: '/',
                //         title: t('Breadcrumb.AppTitle')
                //     },
                //     {
                //         title: t('Breadcrumb.NewPremiumAccount')
                //     }
                // ]}
                loading={isLoading}
                title={t('Plan.Page.Title')}
                titleNote={t('Plan.Page.TitleNote')}
            >
                <div>
                    <PlansFeatures
                        detailActionPlan={goToNewAccountPage}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        accessTokenProvider= {accessTokenProvider}
                        isLoggdIn={true}
                        >
                    </PlansFeatures>
                </div>
            </CSMasterpage>
        </>
    )
}