import { useEffect, useState } from "react";
import accessTokenProvider from '../../../../services/generic/session'
import { useTranslation } from "react-i18next";
import {
    Overlay,
    HorizontalFlow,
    useNotificationsContext
} from "tridonic-web-ui";

import InputV2 from '../../../../components/TDS/InputV2';
import CancelActionConfimation from "../../../../components/parts/CancelActionConfimation";

import services from "../../../../services/generic/services";
import notificationsHelper from "../../../../utils/notificationsHelper";
import storageOverlayType from "../../../../enum/storageOverlayType";


export default function StorageOverlay({ overlayData, visible, setCriticalError, onClose, onSuccessCallback }) {
    const [active, setActive]= useState(true);
    const { t } = useTranslation();
    const { addNotification } = useNotificationsContext()
    const [notifications, setNotifications] = useState('')
    const [notificationsType, setNotificationsType] = useState('')
    const [overlayVisible, setOverlayVisible] = useState(visible)
    const [nodeName, setNodeName] = useState(null)
    const [overlayCancelVisible, setOverlayCancelVisible] = useState(false)
    const [formIsValid, setFormIsValid] = useState(false)
    const [showValidationError, setShowValidationError] = useState(false)

    useEffect(() => {
        
        if(visible == false)
        return; 

        initComponent();      

    }, [visible])

    function initComponent(){

        setNotifications('')
        setNotificationsType('')
        setActive(true)
        
        switch(overlayData.actionType)
        {
            case storageOverlayType.CreateFolder:{
                 setNodeName(null); //TODO: check
            }break;
            default:{ 
                setNodeName(overlayData.name);
            }
        }
        
        setOverlayVisible(visible)
    }

    async function postServer() {
        
        setActive(false)

        let response;

        switch(overlayData.actionType){
            case storageOverlayType.RenameFile:{
                
                let data = {
                    id: overlayData.nodeId,
                    name:nodeName
                };

                response = await services.renameFile({
                    tokenProvider: accessTokenProvider,
                    companyId: overlayData.companyId,
                    parentFolderId: overlayData.parentFolderId,
                    data: data});
            }break;
            case storageOverlayType.RenameFolder:{
                
                response = await services.renameFolder({
                    tokenProvider: accessTokenProvider,
                    companyId: overlayData.companyId,
                    folderId: overlayData.nodeId,
                    data: nodeName});
            }break;
            case storageOverlayType.CreateFolder:{

                response = await services.createFolder({
                    tokenProvider: accessTokenProvider,
                    companyId: overlayData.companyId,
                    parentFolderId: overlayData.parentFolderId,
                    data: nodeName});

            }break;
            default: {
                setNotifications(t('Accounty.OnlineStorage.ErrorServicesEndpoint'))
                setNotificationsType('error')
            }
        }

        if (response.success) {

            addNotification(
                notificationsHelper.success({text:t(`Account.OnlineStorage.GenericModal.${getIndex(overlayData.actionType)}.Messages.Success`)}))
           
            onSuccessCallback(overlayData);

            closeOverlay();

        } else {

            setActive(true);

            if (response.data.isActionError) {

                if (response.data.status === 409 || response.data.status === 404) {
                    setNotifications(t('Account.Users.InviteUser.Message.' + response?.data?.message, { email: email }))
                    setNotificationsType('error')
                } else {
                    let auxResponse = 'A file or folder with the name ' + nodeName + ' already exists'
                    if(response?.data?.message == auxResponse)
                    {
                        switch(overlayData.actionType){
                            case storageOverlayType.RenameFile:{
                                setNotifications(t(`Account.OnlineStorage.GenericModal.${getIndex(overlayData.actionType)}.Messages.ErrorAlreadyExists`))
                                setNotificationsType('error')
                            }break;
                            case storageOverlayType.RenameFolder:
                            case storageOverlayType.CreateFolder:{
                                setNotifications(t(`Account.OnlineStorage.GenericModal.${getIndex(overlayData.actionType)}.Messages.ErrorAlreadyExists`))
                                setNotificationsType('error')
                            }break;
                            default: {
                                setNotifications(t(response?.data?.message))
                                setNotificationsType('error')
                            }
                        }
                    }
                    else
                    {
                        setNotifications(t(response?.data?.message))
                        setNotificationsType('error')    
                    }
                }
            } else {
                setCriticalError(response.data)
            }
        }
    }

    function cancelConfirmationToogle(askForConfirmation){
        setOverlayVisible(!askForConfirmation);
        setOverlayCancelVisible(askForConfirmation);
    }

    function closeOverlay() {
        setOverlayVisible(false);
        setOverlayCancelVisible(false);
        onClose();
    }

    function getIndex(actionType){
        
        if(actionType == undefined)
        return 0; //default position

        return actionType;
    }
    
    return (
        <>

            <Overlay
                actions={[
                    {
                        action: function () { postServer() },
                        title: t(`Account.OnlineStorage.GenericModal.${getIndex(overlayData.actionType)}.ActionButton`),
                        type: 'primary',
                        active: formIsValid
                    },
                    {
                        action: function () { closeOverlay() },  
                        title: t('Account.OnlineStorage.CommonModal.ActionCancel'),
                        type: 'ghost'
                    }
                ]}
                active={active} 
                allowCloseWithActions={false}
                centerContentVertically={false}
                error={false}
                errorTemplate={ t('Account.OnlineStorage.GenericError')}
                height={null}
                loading={false}
                notifications={notifications}
                notificationsType={notificationsType}
                title={ t(`Account.OnlineStorage.GenericModal.${getIndex(overlayData.actionType)}.Action`)}
                visible={overlayVisible}
                widthInColumns= {[12,10,8,8,8]}
            >
                <div>
                    <HorizontalFlow
                        maxItemsPerLine={1}
                        useBottomMargin={true}
                        useTopMargin={false}
                    >
                        {overlayData.actionType == storageOverlayType.RenameFile ?
                            <InputV2
                                active={active}                            
                                type="text"
                                label={t(`Account.OnlineStorage.GenericModal.${getIndex(overlayData.actionType)}.FieldLabel`)}
                                loading={false}
                                setValue={setNodeName}
                                value={nodeName}
                                placeholder={t(`Account.OnlineStorage.GenericModal.${getIndex(overlayData.actionType)}.PlaceHolder`)}  
                                required={true}
                                requiredMessage={t('Account.OnlineStorage.CommonModal.FieldRequiredMessage')}
                                showValidationError={showValidationError}
                                validationCallback={(valid, text) => {  setFormIsValid(valid);  }}
                                validationRegExpString="^[a-zA-Z0-9_\s-]+\.(trgf|traf)$"
                                validationErrorMessage={t('Account.OnlineStorage.CommonModal.FieldCustomErrorMessage')}
                                maxLength={133}
                                // forceValidations={true}
                                helperText={t('Account.OnlineStorage.CommonModal.FieldHelperText')}
                                width="100%"     
                            />
                            : 
                                <InputV2
                                    active={active}                            
                                    type="text"
                                    label={t(`Account.OnlineStorage.GenericModal.${getIndex(overlayData.actionType)}.FieldLabel`)}
                                    loading={false}
                                    setValue={setNodeName}
                                    value={nodeName}
                                    placeholder={t(`Account.OnlineStorage.GenericModal.${getIndex(overlayData.actionType)}.PlaceHolder`)}  
                                    required={true}
                                    requiredMessage={t('Account.OnlineStorage.CommonModal.FieldRequiredMessage')}
                                    showValidationError={showValidationError}
                                    validationCallback={(valid, text) => {  setFormIsValid(valid);  }}
                                    validationRegExpString="^[a-zA-Z0-9_\s-]+$" 
                                    validationErrorMessage={t('Account.OnlineStorage.CommonModal.FieldCustomErrorMessage')}
                                    maxLength={128}
                                    // forceValidations={true}
                                    helperText={t('Account.OnlineStorage.CommonModal.FieldHelperText')}
                                    width="100%"
                                />
                        }
                       
                    </HorizontalFlow>
                </div>
            </Overlay>

            <CancelActionConfimation
                actions={[
                    {
                        action: function () { closeOverlay() },
                        title: t('Common.Actions.Discard'),
                        type: 'primary'
                    },
                    {
                        action: function () { cancelConfirmationToogle(false); },  
                        title: t('Account.OnlineStorage.CommonModal.ActionContinue'),
                        type: 'ghost'
                    }
                ]}
                loading={false}
                title={t(`Account.OnlineStorage.GenericModal.${getIndex(overlayData.actionType)}.CancelTitle`)}
                isVisible={overlayCancelVisible}
                notifications={notifications}
                notificationsType={notificationsType}
                question={t(`Account.OnlineStorage.GenericModal.${getIndex(overlayData.actionType)}.CancelQuestion`)}
            >

            </CancelActionConfimation>
        </>
    )
}