import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history"

const reactPlugin = new ReactPlugin();
const browserHistory = createBrowserHistory({ basename: '' });

const ai = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY || '',
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
})

ai.loadAppInsights()

/**
 * Wraps withAITracking application insights
 * Useful for checking and handle situations where the instrumentation key is null
 * @param {*} component React component
 * @returns component wrapped with application insights or object
 */
function withAITrackingWrapper({
    component
}){
    if (ai && ai.config.instrumentationKey) {
        return withAITracking(reactPlugin, component);
    }
    return component;
}

/**
 * Sends the exception to application insights
 * @param {*} exception exception object 
 */
export function trackException({
    exception
}){
    if (ai && ai.appInsights) {
        ai.appInsights.trackException({ error: exception });
    } else {
        console.error("Application insights not initiated!");
    }
}

// Usage example (wrap the component with this): export default withAppInsights(App);
export default (component) => withAITrackingWrapper({component: component});
// export const appInsights = ai.appInsights