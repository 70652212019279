// @flow
import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import {
  color_ambient_02,
  color_ambient_04,
  density_space_3xs,
  density_space_lg,
  density_space_md,
  density_space_sm,
  density_space_xs,
  shape_radius_sm,
  color_ambient_03,
  useAvailableWidth,
  density_space_xxs,
  font_md,
  sm_or_larger,
  lg_or_larger
} from 'tridonic-web-ui'

const Card = styled.div`
  background-color: ${props => 'transparent'};
  border-radius: ${shape_radius_sm};
  padding-top: ${props => props.customChild ? '0px' : density_space_xxs};
  margin-top: ${props => (props.secondTier ? density_space_sm : '0px')};

  ${sm_or_larger} {
    padding-top: ${props => props.customChild ? '0px' : density_space_xxs};
    margin-top: ${props => (props.secondTier ? density_space_md : '0px')};
  }

  ${lg_or_larger} {
    padding-top: ${props => props.customChild ? '0px' : density_space_xxs};
    margin-top: ${props => (props.secondTier ? density_space_lg : '0px')};
  }
`

const Table = styled.table`
  width: 100%;
  padding: 0px;
  margin: 0px;
  border-spacing: 0px;
  border: 0;
  outline: 0;
  table-layout: fixed;
`

const Label = styled.td`
  color: ${color_ambient_04};
  width: ${props => props.labelWidth};
  ${font_md};
  vertical-align: top;
  padding: 0;
  padding-top: ${props => props.valueIsText ? props.isFirstRow ? '0px' : density_space_xs : props.isFirstRow ? density_space_xs : `calc(${density_space_sm} + ${density_space_3xs})`};
`
const LabelContent = styled.div`
  overflow-wrap: break-word;
  color: ${color_ambient_03};
`

const Value = styled.td`
  overflow-wrap: break-word;
  color: ${color_ambient_02};
  ${font_md};
  vertical-align: middle;
  padding: 0;
  padding-top: ${props => (props.isFirstRow ? '0px' : density_space_xs)};
`

const Separator = styled.td`
  width: ${density_space_sm};
  padding: 0;
  ${sm_or_larger} {
    width: ${density_space_md};
  }
`
const Row = styled.tr`
  padding: 0;
`
const Tbody = styled.tbody``

const defaultColumnsPerBreakpoint = [1, 1, 2, 2, 2]

export default function ListFeatures({ data = [],
  columnsPerBreakpoint = defaultColumnsPerBreakpoint,
  labelWidth = '50%',
}) {

  const [colNumber, setColNumber] = useState(1)

  const { breakpoint } = useAvailableWidth()

  useEffect(() => {
    let numberOfCols = defaultColumnsPerBreakpoint
    if (columnsPerBreakpoint && columnsPerBreakpoint.length === 5) {
      numberOfCols = columnsPerBreakpoint
    }

    switch (breakpoint) {
      case 'xs':
        setColNumber(numberOfCols[0])
        break
      case 'sm':
        setColNumber(numberOfCols[1])
        break
      case 'md':
        setColNumber(numberOfCols[2])
        break
      case 'lg':
        setColNumber(numberOfCols[3])
        break
      case 'xl':
        setColNumber(numberOfCols[4])
        break
      default:
        setColNumber(1)
    }

  }, [breakpoint, columnsPerBreakpoint])

  const colArray = []
  for (let i = 0; i < colNumber; i++) {
    colArray.push({})
  }

  return (
    <>
      <Card>
        <Table>
          <Tbody>
            {data.map((row, rowIndex) => (
              <Row key={`row-${rowIndex}`}>
                <Label labelWidth={labelWidth} valueIsText={typeof row.value === 'string'} isFirstRow={rowIndex === 0}>
                  <LabelContent title={row.label}>
                    {row.label}
                  </LabelContent>
                </Label>
                <Separator />
                <Value isFirstRow={rowIndex === 0}>{row.value}</Value>
              </Row>
            ))}
          </Tbody>
        </Table>
      </Card>
    </>
  )
}
