import React, { useRef, useState, useCallback, useEffect, useMemo, useContext } from 'react';
import { useParams, useLocation, useHistory } from "react-router";
import {Breadcrumb, Checkbox,HorizontalFlow, TableListView, HorizontalFlex, Input, Dropdown, Button, useNotificationsContext, useThemeContext, InputSearch, ListView, Svg,TextWithIcon } from 'tridonic-web-ui';
import { useTranslation } from 'react-i18next';
import accessTokenProvider from '../../../../services/generic/session'
import CompanyTabs from '../../../../enum/CompanyTabs';
import services from '../../../../services/generic/services';
import permition from '../../../../services/generic/permition';
import CompanyMasterpage from '../../../../components/CompanyMasterpage';
import StorageTabPageStyle from './StorageTabPageStyle';
import notificationsHelper from '../../../../utils/notificationsHelper';
import StorageOverlay from "./StorageOverlay.jsx"; 
import StoragePermissionsOverlay from "./StoragePermissionsOverlay.jsx"; 
import StorageUploadOverlay from "./StorageUploadOverlay.jsx"; 
import StorageDeleteOverlay from "./StorageDeleteOverlay.jsx"; 
import storageOverlayType from "../../../../enum/storageOverlayType";
import { useAppContext } from '../../../../AppContext'
import TRAFIcon from "./StorageTabPageFunctions"; 

export default function StorageTabPage({ 

}) {

    // const history = useHistory();
    // const { storagePath, setStoragePath } = useAppContext()
    const location = useLocation();
    const companyNameParam = location.state?.companyName;
    const { addNotification } = useNotificationsContext();
  
    const { t } = useTranslation();
    const {theme} = useThemeContext();
    const { EmptyDiv } = useMemo(() => StorageTabPageStyle(theme), [theme]);
    const [isLoading, setIsLoading] = useState(true);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [criticalError, setCriticalError] = useState('');
    
    const { companyId } = useParams();
    const [userId, setUserId] = useState();
    const [isAdmin, setIsAdmin] = useState();
    const [isAccountHolder, setIsAccountHolder] = useState(false);
    const [rootFolderId, setRootFolderId] = useState(null);
    
    const [breadcrumbStorage, setbreadcrumbStorage] = useState([]);
    const [companyStoreDataV2, setCompanyStoreDataV2] = useState([]);
    const [searchBy, setSearchBy] = useState();
    
    const [storageOverlayVisible, setStorageOverlayVisible] = useState(false)
    const [storageDeleteOverlaylayVisible, setStorageDeleteOverlayVisible] = useState(false)
    const [storagePermissionsOverlayVisible, setStoragePermissionsOverlayVisible] = useState(false)
    const [storageUploadOverlayVisible, setStorageUploadOverlayVisible] = useState(false)
    const [contextOverlayData, setContextOverlayData] = useState({})
    
    const storageContextActionsNoAdminV2 = [{
            id: storageOverlayType.Download,
            icon: 'download',
            title: t(`Account.OnlineStorage.ContextMenu.Download`), 
            isFolder: false 
        }];
    const storageContextActionsV2 = [
        {
            id: storageOverlayType.RenameFolder,
            icon: 'pencil',
            title: t(`Account.OnlineStorage.ContextMenu.Rename`), 
            isFolder: true 
        },
        {
            id: storageOverlayType.RenameFile,
            icon: 'pencil',
            title: t(`Account.OnlineStorage.ContextMenu.Rename`), 
            isFolder: false 
        },
        {
            id: storageOverlayType.DeleteFolder,
            icon: 'clear',
            title: t(`Account.OnlineStorage.ContextMenu.Delete`), 
            isFolder: true
        },
        {
            id: storageOverlayType.DeleteFile,
            icon: 'clear',
            title: t(`Account.OnlineStorage.ContextMenu.Delete`), 
            isFolder: false 
        },
        {
            id: storageOverlayType.Download,
            icon: 'download',
            title: t(`Account.OnlineStorage.ContextMenu.Download`), 
            isFolder: false 
        }];

    const addContextMenuV2 = isAdmin ? storageContextActionsV2 : storageContextActionsNoAdminV2
    
    const firstLoad = () => {
        fetchContent( {id:companyId});
        setBreadcrumbDefault();
        setIsFirstLoad(false);
    };

    useEffect(() => { 

        fetchUserData();

    }, []);

    useEffect(() => { 

        if(isAdmin === undefined)
            return;

        firstLoad(); 
    }, [isAdmin]);

    useEffect(() => {

        if(searchBy === undefined)
        return; 

        let currentFolderId = breadcrumbStorage[breadcrumbStorage.length-1]?.id;
        fetchContent(
        {
            id:companyId, 
            folderId: validateIsRoot(currentFolderId),
            searchName: searchBy
        });
    }, [searchBy])

    
    async function fetchStorageData({companyId, folderId, searchName, onSuccess, onError}) { 
        services
            .getStorageData({ 
                tokenProvider: accessTokenProvider, 
                companyId: companyId, 
                folderId: folderId,
                searchName: searchName })
            .then(results => {
                if (results.success) {
                    onSuccess(results.data);
                } else {
                   onError(results.data);
                }
            }).catch((err) => {
                addNotification(
                    notificationsHelper.critical(
                        {text:t(`Account.OnlineStorage.LoadingError`)}))

                console.error(err);
            }).finally(() => {
                setIsLoading(false);
            });
    };
 
    
    async function  fetchUserData(){

        const userResponse = await services.getUser({
            tokenProvider: accessTokenProvider
        })

        if (userResponse.success) {           
            setUserId(userResponse.data.id);
            let companyData = userResponse.data.companies.find(x => x.id == companyId);
            setIsAccountHolder(permition.isCompanyAccountHolderV2({company: companyData, userId: userResponse.data.id})); 
            setIsAdmin(permition.isAdminInCompanyV2(
                {
                    companies: userResponse.data.companies,
                    companyId: companyId
                })); 
            setIsLoading(false);
        } else {
            if (userResponse.data.isActionError) {
                addNotification(notificationsHelper.critical({text:t(userResponse.data.message)}))
            } else {
                setCriticalError(response.data)
            }
        }
    }
  
    function validateIsRoot(folderId){
        if(folderId == undefined){
            folderId = rootFolderId
        }

        return folderId;
    }

    function fetchContent({id, folderId, searchName}, onSuccessCallback){
        
        setIsLoading(true);

        fetchStorageData({
            companyId: id,  
            folderId: folderId, 
            searchName: searchName,
            onSuccess: (data) => {
                
                if(data.isRootFolder){
                    setRootFolderId(data.folderId);
                }

                 setCompanyStoreDataV2(
                     convertStorageDataV2(
                         {
                             companyStorageData:data.items, 
                             parentFolderId: folderId
                         }));
                        
                setIsLoading(false);

                if(onSuccessCallback != null || onSuccessCallback != undefined){
                    onSuccessCallback();
                }
            },
            onError: (data) => {

                setIsLoading(false);

                if (data.isActionError) {
                    addNotification(notificationsHelper.critical({text:t(results?.data?.message)}))
                } else {
                    addNotification(
                        notificationsHelper.critical(
                            {text:t(`Account.OnlineStorage.LoadingError`)}))
                }
            }
            }); 
    }

    function setBreadcrumb({nodeId, title}){

        setbreadcrumbStorage( arr => [...arr, 
            {
                id: nodeId,
                title: title
            }
        ]);

        setSearchBy(); 
    }

    function setBreadcrumbPull(id){

        if(id == undefined || id  == null)
        {
            setBreadcrumbDefault();
        
        } else {
            
            let array = breadcrumbStorage;
            let a = array.splice(
                        array.findIndex(item => item.id === id)+1);
            
            setbreadcrumbStorage(array);
        }
    }

    function setBreadcrumbDefault(){ 
        setbreadcrumbStorage([{ title: t('Breadcrumb.StorageRoot') }]);
    }

    function convertStorageDataV2({companyStorageData, parentFolderId}) {

        return companyStorageData?.map(storageItem => ({
            columns: [
                <>
                     { storageItem.isDirectory ? 
                     <TextWithIcon key="x1" icon="folder" text={storageItem.name} />
                     :
                     <>
                         <TRAFIcon /> <span> {storageItem.name} </span>
                    </>
                     }
                </>
            ],
            title: '',
            id: storageItem.id,
            name: storageItem.name,
            isFolder: storageItem.isDirectory,
            moreActions:  getContextMenu(storageItem),
            moreActionsActive:hasContextMenu(storageItem)
                
        }));
    }

    function getContextMenu(storageItem){
        return addContextMenuV2.filter(function (item){
            return item.isFolder === storageItem.isDirectory;
        });
    }

    function hasContextMenu(storageItem){
       return addContextMenuV2.filter(function (item){
            return item.isFolder === storageItem.isDirectory;
        }).length > 0;
    }


    function storageContextMenu({actionType, companyId, parentFolderId, nodeId, name}){

        var parentFolderId = validateIsRoot(parentFolderId);

        let data = {
            actionType: actionType,
            companyId: companyId,
            parentFolderId: parentFolderId,
            nodeId: nodeId,
            name: name
        };

        setContextOverlayData(data);

        switch(actionType){
            case storageOverlayType.RenameFile:
            case storageOverlayType.RenameFolder:
            case storageOverlayType.CreateFolder:{

                setStorageOverlayVisible(true);

            }break;
            case storageOverlayType.DeleteFolder:
            case storageOverlayType.DeleteFile:{

                setStorageDeleteOverlayVisible(true);

            }break;
            case storageOverlayType.PermissionsFolder:{

                setStoragePermissionsOverlayVisible(true);

            }break;
            case storageOverlayType.Upload:{

                setStorageUploadOverlayVisible(true);

            }break;
            case storageOverlayType.Download:{

                downloadFile(data);

            }break;
            default: {
                addNotification(
                    notificationsHelper.critical(
                        {text:t(`Account.OnlineStorage.ErrorServicesnotImplemented`)}))
            }
        }
    }

    async function downloadFile(data) {

        var response = await services.downloadFile({
            tokenProvider: accessTokenProvider,
            companyId: data.companyId,
            folderId: data.parentFolderId, 
            filenodeId: data.nodeId,
            filename: data.name
        });

        if (response.success) {
            addNotification(
                notificationsHelper.success(
                    {text:t(`Account.OnlineStorage.DownloadModal.Success`)}))
           
        } else {
            if (response.data.isActionError) {
                addNotification(
                    notificationsHelper.critical(
                        {text:t(`Account.OnlineStorage.DownloadError`) + t(response?.data?.message)}))
            } else {
                addNotification(
                    notificationsHelper.critical(
                        {text:t(`Account.OnlineStorage.DownloadError`)}))
            }
        }
    }

    return (
        <CompanyMasterpage
            loading={isLoading}
            title={companyNameParam}
            companyId={companyId}
            tabsEnabled={true}
            tabSelectedIndex={CompanyTabs.Storage}
            criticalError={criticalError}
            isAccountHolder={isAccountHolder}
            isAdmin={isAdmin}
            >
            {
                <>
                <Breadcrumb data={breadcrumbStorage} onClick={(e, event) => {
                        
                        
                        fetchContent(
                            {
                                id:companyId, 
                                folderId: e.id
                            });
                        
                        setBreadcrumbPull(e.id);
                    }}
                />
                    <HorizontalFlex
                        width="100%"
                        maxItemsPerLine=""
                        xsItems={1}
                        smItems={2}
                        mdItems={4}
                        lgItems={4}
                        xlItems={4}
                        useTopMargin={false}
                        useBottomMargin={true}
                        horizontalGap=""
                        verticalGap=""
                        >
                        <InputSearch
                            id="storageSearch"
                            width=""
                            active={true}
                            useSearchButton={true}
                            value={searchBy}
                            placeholder={t(`Account.OnlineStorage.SearchPlaceholder`)}
                            onValueChange={(e) => setSearchBy(e)}                         
                        />
                         { isAdmin ? 
                            <Button
                                type="primary"
                                active={isAdmin}
                                leadingIcon="add"
                                text={t(`Account.OnlineStorage.NewFolder`)}
                                hideLabelOnSmallBreakpoints={false}
                                handleTextAsIcon={false}
                                overrideWithNeutralColors={false}
                                alternativeBackground={false}
                                onClick={
                                    function noRefCheck() { 
        
                                        let currentFolderId = breadcrumbStorage[breadcrumbStorage.length-1].id;
                                        currentFolderId = validateIsRoot(currentFolderId);
        
                                        storageContextMenu({ 
                                            actionType: storageOverlayType.CreateFolder,
                                            companyId: companyId,
                                            parentFolderId: currentFolderId}); 
                                    }}
                            />
                            : 
                            <div></div>
                        }

                    </HorizontalFlex>

                    { isLoading === false && (!companyStoreDataV2 || companyStoreDataV2.length === 0) ?
                    
                    <TextWithIcon  icon="info" text={t(`Account.OnlineStorage.NoItems`)}/> :

                    <TableListView
                        showTitles={false}
                        active={true} 
                        loading={isLoading}
                        alternativeBackground={true}
                        onAction={function (item, action, index, event) { 

                            let currentFolderId = breadcrumbStorage[breadcrumbStorage.length-1].id;
                                currentFolderId = validateIsRoot(currentFolderId);

                             storageContextMenu({ 
                                 actionType: action.id,
                                 companyId: companyId,
                                 parentFolderId: currentFolderId,
                                 nodeId: item.id,
                                 name: item.name}); 

                         }}
                        onDetailClick={function (item, event) { 
                            if(item.isFolder == true){
                                
                                fetchContent( 
                                    {id:companyId, folderId: item.id},
                                    function(){
                                         
                                         setBreadcrumb({nodeId: item?.id, title: item.name });
                                    });
                            };
                        }}
                        columns={{
                            dataColumns: [
                            {
                                title: 'Filename',
                                width: '100%'
                            }],
                            moreActionsAlignIconLeft: true,
                            moreActionsIcon: 'more',
                            titleTitle: '',
                            titleWidth: '20px'
                        }}
                        data={companyStoreDataV2}
                        />
                    }

                    {storageOverlayVisible ?
                        <StorageOverlay key={0} overlayData={contextOverlayData}
                            visible={storageOverlayVisible}
                            setCriticalError={setCriticalError}
                            onSuccessCallback={(data) => fetchContent(
                                {
                                    id:data.companyId, 
                                    folderId: data.parentFolderId
                                })}
                            onClose={() => {setStorageOverlayVisible(false); }}>
                        </StorageOverlay> 
                        : null
                    }
                    
                    <StorageDeleteOverlay key={1} overlayData={contextOverlayData}
                        visible={storageDeleteOverlaylayVisible}
                        setCriticalError={setCriticalError}
                        onSuccessCallback={(data) => fetchContent(
                            {
                                id:data.companyId, 
                                folderId: data.parentFolderId
                            })}
                        onClose={() => {setStorageDeleteOverlayVisible(false); }}
                    ></StorageDeleteOverlay>


                    {storagePermissionsOverlayVisible ?
                        <StoragePermissionsOverlay key={0} overlayData={contextOverlayData}
                            setCriticalError={setCriticalError}
                            onSuccessCallback={(data) => fetchContent(
                                {
                                    id:data.companyId, 
                                    folderId: data.parentFolderId
                                })}
                            onClose={() => {setStoragePermissionsOverlayVisible(false); }}
                        ></StoragePermissionsOverlay>
                        : null
                    }

                   {storageUploadOverlayVisible ?
                        <StorageUploadOverlay key={0} overlayData={contextOverlayData}
                            setCriticalError={setCriticalError}
                            onSuccessCallback={(data) => fetchContent(
                                {
                                    id:data.companyId, 
                                    folderId: data.parentFolderId
                                })}
                            onClose={() => {setStorageUploadOverlayVisible(false); }}
                        ></StorageUploadOverlay> : null
                    }
                </>
            }
        </CompanyMasterpage>
    )
}