import { useEffect, useState } from "react";
import accessTokenProvider from '../../../../services/generic/session'
import { useTranslation } from "react-i18next";
import {
    Overlay,
    color_critical_strong,
    HorizontalFlow,
    useNotificationsContext,
    Input,
    Checkbox,
    CheckboxList,
    Dropdown,
    Tooltip,
    Button ,
    Svg 
} from "tridonic-web-ui";

import services from "../../../../services/generic/services";
import { useParams, useLocation } from "react-router";
import CancelActionConfimation from "../../../../components/parts/CancelActionConfimation";
import notificationsHelper from "../../../../utils/notificationsHelper";
import storageOverlayType from "../../../../enum/storageOverlayType";



export default function StoragePermissionsOverlay({ overlayData, setCriticalError, onClose, onSuccessCallback }) {
    
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const { addNotification } = useNotificationsContext()
    const [active, setActive]= useState(true);
    const [overlayVisible, setOverlayVisible] = useState(true)
    const [overlayCancelVisible, setOverlayCancelVisible] = useState(false)
    const [notifications, setNotifications] = useState('')
    const [notificationsType, setNotificationsType] = useState('')
    const { companyId } = useParams()
    const [companyUsers, setCompanyUsers] = useState([])
 
    useEffect(() => {

        setNotifications('')
        setNotificationsType('')

        getCompanyUsers()

    }, [])

    
    // async function submitForm() {
    //     setIsLoading(true);

    //     let companyForm = {
    //         countryCode: selectedCountry,
    //         name: formCompanyName,
    //         vat: formCompanyVat,
    //         phone: formCompanyPhone,
    //         city: formCompanyCity,
    //         address: formCompanyAddress,
    //         postalCode: formCompanyPostalCode
    //     };

    //     let response = await services.editCompany({ tokenProvider: accessTokenProvider, companyId: companyId, data: companyUsers });
    //     if (response.success) {
    //         addNotification(notificationsHelper.success({text:t('Account.Company.CompanySection.EditName.Message.Success')}));
    //         setIsFormDirty(false);
    //         setCompanyData(response.data);
    //     } else {
    //         addNotification({ text: t(response?.data?.message), type: 'critical' });
    //     }
    //     setIsLoading(false);    
    // }

    async function getCompanyUsers(){
        setIsDataLoaded(false);
        setIsLoading(true);
        Promise.all([
            await services.getCompanyByCompanyId({tokenProvider: accessTokenProvider, companyId: companyId}),
            await services.getPermissionsData({ tokenProvider: accessTokenProvider, companyId: companyId, folderId:overlayData.nodeId })
        ]).then(results => {
            let [userResponse, permissionsUserResponse] = results;

            setCompanyUsers(convertCompanyUsers({ 
                companyUsers: userResponse.data.users,
                permissionsUsers: permissionsUserResponse.data}));

        }).catch((err) => {
            console.error(err);
            setCriticalError(err)
        }).finally(() => {
            setIsLoading(false);
            setIsDataLoaded(true);
        });


        // const response = await services.getCompanyByCompanyId({
        //     tokenProvider: accessTokenProvider,
        //     companyId: companyId
        // });
        // if (response.success) {
        //     return convertCompanyUsers(
        //         { companyUsers: response.data.users});
        // } else {
        //     if (response.data.isActionError) {
        //         if (response.data.status === 404) {
        //             response.data.message = 'Account.Message.NotFound'
        //             setCriticalError(response.data)
        //             setIsLoading(false)
        //             return
        //         }
        //         setCriticalError(response.data)
        //     } else {
        //         setCriticalError(response.data)
        //     }
        // }
    }
        
    function convertCompanyUsers({ companyUsers, permissionsUsers}) {
        
                
        let cleanUsers = companyUsers.map( item => {
            const { id: userId, ...rest } = item;
            return { userId, ...rest }
        });

        let mergedUsers = cleanUsers.map(user => (
            {
                ...user, 
                ...permissionsUsers.find(permission => permission.userId === user.userId)
            }));

        return mergedUsers?.map(user => (
            {
                id: user.id,
                userId: user.userId,
                name: user.name,
                hasRead: user.hasRead,
                hasWrite: user.hasWrite
            }));
    }

    const updateUserSelection = (name, index) => (eventValue) => {
        let newArr = companyUsers.map((item, i) => {
          if (index == i) {
            return { ...item, [name]: eventValue };
          } else {
            return item;
          }
        });
        setCompanyUsers(newArr);
      };

    // function updateUserSelection1({user, isChecked})
    // {
    //     alert(22);
    //     alert(user);
    //     console.log(user);

    //     alert(isChecked);
    // }

    function cancelConfirmationToogle(askForConfirmation){
        setOverlayVisible(!askForConfirmation);
        setOverlayCancelVisible(askForConfirmation);
    }

    function closeOverlay() {
        setOverlayVisible(false);
        setOverlayCancelVisible(false);
        onClose();
    }


    async function postServer() {
        setIsLoading(true);
        setActive(false)

        let response; 
        
        response = await services.updatePermissionsData({
            tokenProvider: accessTokenProvider,
            companyId: overlayData.companyId,
            folderId: overlayData.nodeId,
            data: companyUsers
        });

        if (response.success) {

            addNotification(notificationsHelper.success({text:t(`Account.OnlineStorage.PermissionsModal.Success`)}))
           
            onSuccessCallback(overlayData);
            
            setIsLoading(false);
            
            closeOverlay();

        } else {
            if (response.data.isActionError) {
                    setNotifications(t(response?.data?.message))
                    setNotificationsType('error')
            } else {
                setCriticalError(response.data)
            }
        }

        setIsLoading(false);
    }

    return (
        <>
            <Overlay
                actions={[
                    {
                        action: function () { postServer() },
                        title: t(`Account.OnlineStorage.PermissionsModal.ActionButton`),
                        type: 'primary'
                    },
                    {
                        action: function () { cancelConfirmationToogle(true); },  
                        title: t('Account.OnlineStorage.CommonModal.ActionCancel'),
                        type: 'ghost'
                    }
                ]}
                active={active} 
                allowCloseWithActions={false}
                centerContentVertically={false}
                error={false}
                errorTemplate={ t('Account.OnlineStorage.GenericError')}
                height={null}
                loading={false}
                notifications={notifications}
                notificationsType={notificationsType}
                title={ t(`Account.OnlineStorage.PermissionsModal.Title`)}
                visible={overlayVisible}
                widthInColumns= {[12,10,8,8,8]}
            >
                <div>
                    <HorizontalFlow
                        horizontalGap=""
                        lgItems="1"
                        maxItemsPerLine=""
                        mdItems="1"
                        smItems={1}
                        useBottomMargin
                        useTopMargin
                        verticalGap=""
                        width="100%"
                        xlItems="1"
                        xsItems={1}>
                            <label>{ t(`Account.OnlineStorage.PermissionsModal.Disclaimer`)}</label>
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "left" }}>{ t(`Account.OnlineStorage.PermissionsModal.ListColumns.User`)}</th>
                                        <th style={{ textAlign: "center", width:  100 }}>
                                        <Tooltip
                                            align="center"
                                            display="inline-block"
                                            position="top"
                                            tooltipContent={t(`Account.OnlineStorage.PermissionsModal.ToolTips.Read`)}
                                            tooltipMaxWidth="">
                                            {t(`Account.OnlineStorage.PermissionsModal.ListColumns.PermissionsRead`)}
                                        </Tooltip>
                                        </th>
                                        <th style={{ textAlign: "center", width:  100 }}>
                                        <Tooltip
                                            align="center"
                                            display="inline-block"
                                            position="top"
                                            tooltipContent={t(`Account.OnlineStorage.PermissionsModal.ToolTips.Write`)}
                                            tooltipMaxWidth="">
                                            {t(`Account.OnlineStorage.PermissionsModal.ListColumns.PermissionsWrite`)}
                                        </Tooltip>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {companyUsers.map((user, index) => (
                                    <tr key={index}>
                                        <td style={{ textAlign: "left"}}>
                                            <span>{user.name}</span>
                                        </td>
                                        <td style={{ textAlign: "center", paddingLeft:  40  }}>
                                            <Checkbox 
                                                checked={user.hasRead}
                                                onStateChange={updateUserSelection("hasRead", index)}
                                            />
                                        </td>
                                        <td style={{ textAlign: "center", paddingLeft:  40 }}>
                                            <Checkbox 
                                                checked={user.hasWrite}
                                                onStateChange={updateUserSelection("hasWrite", index)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                    </HorizontalFlow>

                </div>

            </Overlay> 

            <CancelActionConfimation
                actions={[
                    {
                        action: function () { closeOverlay() },
                        title: t('Common.Actions.Discard'),
                        type: 'primary'
                    },
                    {
                        action: function () { cancelConfirmationToogle(false); },  
                        title: t('Account.OnlineStorage.CommonModal.ActionContinue'),
                        type: 'ghost'
                    }
                ]}
                loading={false}
                title={t(`Account.OnlineStorage.PermissionsModal.CancelTitle`)}
                isVisible={overlayCancelVisible}
                notifications={notifications}
                notificationsType={notificationsType}
                question={t(`Account.OnlineStorage.PermissionsModal.CancelQuestion`)}
            >
            </CancelActionConfimation>
        </>
    )
}