import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import CompanyTabs from "../enum/CompanyTabs";
import CSMasterpage from "./CSMasterpage";

export default function CompanyMasterpage({
    breadcrumb = null,
    footerActions = null,
    headerActions = null,
    loading,
    title,
    titleNote,
    companyId = null,
    companyEnable,
    tabSelectedIndex,
    children,
    criticalError,
    isAccountHolder,
    isAdmin
}) {

    const { t } = useTranslation()
    const history = useHistory()
    const tabs=[];

    if( isAdmin || isAccountHolder){
    tabs.push( {
        index: CompanyTabs.PremiumFeatures,
        active: true,
        selected: tabSelectedIndex === CompanyTabs.PremiumFeatures,
        text: t('Account.PremiumFeatures')
    })
    }

    if(isAccountHolder){
        tabs.push( {
            index: CompanyTabs.Settings,
            active: true,
            selected: tabSelectedIndex === CompanyTabs.Settings,
            text: t('Account.Company.Title')
        })
    }

    if( isAdmin || isAccountHolder){
        tabs.push(  {
            index: CompanyTabs.Members,
            active: companyEnable,
            selected: tabSelectedIndex === CompanyTabs.Members,
            text: t('Account.Users.Title')
        })
    }

    tabs.push( {
        index: CompanyTabs.Storage,
        active: companyEnable,
        selected: tabSelectedIndex === CompanyTabs.Storage,
        text: t('Account.OnlineStorage.Title')
    })


    // tabs.push( {
    //     index: CompanyTabs.Invoices,
    //     active: false,
    //     selected: tabSelectedIndex === CompanyTabs.Invoices,
    //     text: 'Invoices'
    // })

    async function tabClickedCallback({ selectTab }) {
        let basePath = `/account/${companyId}/`;
        switch(selectTab?.index) {
            case CompanyTabs.PremiumFeatures:
                history.push({ pathname: `${basePath}premiumFeatures`, state: {enable: companyEnable} });
                break;
            case CompanyTabs.Members:
                history.push({ pathname: `${basePath}user`, state: {companyName: title} });
                break;
            case CompanyTabs.OldCompany:
                history.push({ pathname: `${basePath}company`, state: {companyName: title} });
                break;
            case CompanyTabs.Settings:
                history.push({ pathname: `${basePath}settings`, state: {companyName: title} });
                break;
            case CompanyTabs.Storage:
                history.push({ pathname: `${basePath}storage`, state: {companyName: title} });
                break;
            default:
                console.log("The route does not exist");    
        }
            
    }

    return (
        <CSMasterpage
             breadcrumb = {breadcrumb}
             footerActions = {footerActions}
             headerActions = {headerActions}
             leadingAction={() => window.open(process.env.REACT_APP_URL_CS + "/#/devicegenerator/company/" + companyId, "_blank")}
             leadingActionTitle={t('Account.DeviceGENERATOR')}
             //loading = {loading}
             title = {title}
             titleNote = {titleNote}
             trailingAction={() => window.open(process.env.REACT_APP_URL_CS + "/#/deviceanalyser/company/" + companyId, "_blank")}
             trailingActionTitle={t('Account.DeviceANALYSER')}
             tabWidth = "224"
             tabs= {tabs}
             tabClickedCallback = { tabClickedCallback }
             children={ children }
             criticalError={criticalError}>

        </CSMasterpage>
    )
}

