// Util used to determine if the forgot password flow was triggered

const STORAGE_KEY = 'forgot_password';

export function getSessionForgetPassword() {
    return sessionStorage.getItem(STORAGE_KEY);
}

export function setSessionForgotPassword() {
    sessionStorage.setItem(STORAGE_KEY, 'true');
}

export function removeSessionForgotPassword() {
    sessionStorage.removeItem(STORAGE_KEY);
}