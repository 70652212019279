
import React from 'react'
import styled from '@emotion/styled'
import {
    font_md,
    Overlay
} from 'tridonic-web-ui'

const MessageText = styled.div`
    ${p => font_md};
`
export default function CancelActionConfimation({
    actions,
    isVisible,
    title,
    question,
    message = null,
    notifications = null,
    notificationsType = null }) {



    return (
        <Overlay
            actions={actions}
            active={true}
            centerContentVertically={true}
            errorTemplate="Something went wrong. Please try again later."
            height="400px"
            loading={false}
            notifications={notifications}
            notificationsType={notificationsType}
            title={title}
            visible={isVisible}
        >
            <MessageText>
                {question}
            </MessageText>
            {message !== null ?
                <>
                    <br></br>
                    <MessageText>
                        {message}
                    </MessageText>
                </>
                : <></>}
        </Overlay>
    )
}
