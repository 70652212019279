
// const initialState = {
//   lang: 'en-US',
//   breadcrumb: [{ title: 'Home', url: '/' }],
//   masterpageLoading: false,
//   masterpageData: [],
//   masterpageTitle: '',
//   masterpageBreadcrumb: null,
//   storagePath: ""
// }


import React, { useState } from 'react'


const AppContext = React.createContext();
AppContext.displayName = 'AppContext'

function AppProvider(props) {

  const [lang, setLang] = useState('en-US')
  const [storagePath, setStoragePath] = useState('/')

  const value = {
    // cross application about popup
    lang,
    setLang,

    // cross application dev mode (used to show not supported TDS features)
    storagePath,
    setStoragePath,
  }

  return <AppContext.Provider value={value} {...props} />
}

function useAppContext() {
  const context = React.useContext(AppContext)
  if (context === undefined) { throw new Error('Context error') }
  else { return context }
}

export { AppProvider, useAppContext }


// //https://blog.logrocket.com/use-hooks-and-context-not-react-and-redux/
// // store.js
// import React, { createContext, useReducer } from 'react'


// const initialState = {
//   lang: 'en-US',
//   breadcrumb: [{ title: 'Home', url: '/' }],
//   masterpageLoading: false,
//   masterpageData: [],
//   masterpageTitle: '',
//   masterpageBreadcrumb: null
// }
// const store = createContext(initialState)
// const { Provider } = store

// // eslint-disable-next-line react/prop-types
// const StateProvider = ({ children }) => {
//   const [state, dispatch] = useReducer((state, action) => {

//     if (action.type === 'SET_MASTERPAGE_TITLE') {
//       const x = { ...state, masterpageTitle: { ...state.masterpageTitle, title: action.value } }
//       return x;
//     }

//     if (action.type === 'SET_MASTERPAGE_LOADING') {
//       const x = { ...state, masterpageLoading: { ...state.masterpageLoading, loading: action.value } }
//       return x;
//     }


//     if (action.type === 'SET_MASTERPAGE') {
//       const x = { ...state, masterpageData: action.value }
//       return x;
//     }
//     if (action.type === 'SET_LANG') {
//       const x = { ...state, lang: action.value }
//       return x;
//     } else if (action.type === 'SET_BREADCRUMB') {
//       const x = { ...state, breadcrumb: action.value }
//       return x;
//     }
//     else {
//       throw new Error('Unsupported action type')
//     }
//   }, initialState)

//   return <Provider value={{ state, dispatch }}>{children}</Provider>
// }

// export { store, StateProvider }
