import { useEffect, useState } from "react";
import accessTokenProvider from '../../../../services/generic/session'
import { useTranslation } from "react-i18next";
import {
    Overlay,
    HorizontalFlow,
    Input,
    useNotificationsContext,
    TextWithIcon
} from "tridonic-web-ui";
//import CancelActionConfimation from "../../../../components/parts/CancelActionConfimation";
//import CheckboxsView from "../../../../components/CheckboxsView";
import InviteType from "../../../../enum/InviteType";

import services from "../../../../services/generic/services";
import notificationsHelper from "../../../../utils/notificationsHelper";


export default function InviteUserOverlay({ companyData, visible, invites, companyUsers, setCriticalError, onClose, loadDataCallback }) {
    const [active, setActive]= useState(true);
    const { t } = useTranslation();
    const { addNotification } = useNotificationsContext()
    const [notifications, setNotifications] = useState('')
    const [notificationsType, setNotificationsType] = useState('')
    const [overlayVisible, setOverlayVisible] = useState(false)
    const [email, setEmail] = useState(null)
    const [overlayCancelVisible, setOverlayCancelVisible] = useState(false);
    const [roles, setRoles] = useState([])
    const [emailIsValid, setEmailIsValid] = useState(false)
    const [showValidationError, setShowValidationError] = useState(false)
    
    useEffect(() => {
        //reset notification state
        setNotifications('')
        setNotificationsType('')
       
        setRoles(companyData?.roles?.map((item) => ({
                checked: item.default,
                text: t('PremiumAccounts.CardView.' + item.companyGroupKind),
                companyRoleId: item.id,
                active: !item?.default
            }
        )))

        setOverlayVisible(visible)

    }, [companyData, visible, t])

    async function sendInvitation() {
        // Check if the total number of invites and company users exceeds the limit (3)
        const totalUsers = invites + companyUsers + 1;
        const userLimit = 3;
        if (totalUsers > userLimit) {
            setNotifications(t('Account.Users.InviteUser.Message.ExceedsUserCount'));
            setNotificationsType('error');
            return;
        }
    
        // Create an array to store selected company role IDs
        const selectedCompanyRoles = roles.filter(role => role.text == 'Collaborator').map(role => role.companyRoleId);

        // Deactivate the form during the invitation process
        setActive(false);
    
        // Prepare the invite data
        const invite = {
            email: email,
            companyId: companyData.companyId,
            companyRoleIds: selectedCompanyRoles,
            type: InviteType.Member,
            url: '/invites/accept/{0}/{1}',
        };
    
        try {
            // Send the invite using an asynchronous service call
            const response = await services.sendInvite({
                tokenProvider: accessTokenProvider,
                companyId: companyData.companyId,
                data: invite,
            });
    
            if (response.success) {
                // If the invite was sent successfully, show a success notification
                const successMessage = t('Account.Users.InviteUser.Message.Success', { email: email });
                setNotifications(successMessage);
                setNotificationsType('success');
                addNotification(notificationsHelper.success({ text: successMessage }));
    
                // Reset the email field and reload data
                setEmail('');
                loadDataCallback();
                onClose();
            } else {
                // Handle errors during the invitation process
                if (response.data.isActionError) {
                    const errorMessage = response?.data?.status === 409 || response?.data?.status === 404 ?
                        t('Account.Users.InviteUser.Message.' + response?.data?.message, { email: email }) :
                        t(response?.data?.message);
    
                    setNotifications(errorMessage);
                    setNotificationsType('error');
                } else {
                    // Set a critical error in case of non-action errors
                    setCriticalError(response.data);
                }
            }
        } catch (error) {
            // Handle any unexpected errors during the invitation process
            console.error('Error sending invitation:', error);
            setNotifications(t('Account.Users.InviteUser.Message.ErrorSendingInvite'));
            setNotificationsType('error');
        } finally {
            // Activate the form again after the invitation process
            setActive(true);
        }
    }
    
    // async function sendInvitation() {
    //     const companyRoles = [];
    //     for (let i = 0; i < roles.length; i++) {
    //         if (roles[i].checked) {
    //             companyRoles.push(roles[i].companyRoleId);
    //         }
    //     }
    //     setActive(false)
    //     const invite = {
    //         email: email,
    //         companyId: companyData.companyId,
    //         companyRoleIds: companyRoles,
    //         type: InviteType.Member,
    //         url: '/invites/accept/{0}/{1}'
    //     }
   
    //     const response = await services.sendInvite({
    //         tokenProvider: accessTokenProvider,
    //         companyId: companyData.companyId,
    //         data: invite
    //     });

    //     if (response.success) {
    //         setNotifications(t('Accounty.Users.InviteUser.Message.Success', { email: email }))
    //         setNotificationsType('success')
    //         addNotification(notificationsHelper.success({text:t('Account.Users.InviteUser.Message.Success', { email: email })}))
    //        // If successe reset filedS
    //         setEmail('');
    //         loadDataCallback()
    //         onClose()

    //     } else {
    //         if (response.data.isActionError) {

    //             if (response.data.status === 409 || response.data.status === 404) {
    //                 setNotifications(t('Account.Users.InviteUser.Message.' + response?.data?.message, { email: email }))
    //                 setNotificationsType('error')
    //             } else {
    //                 setNotifications(t(response?.data?.message))
    //                 setNotificationsType('error')
    //             }
    //         } else {
    //             setCriticalError(response.data)
    //         }
    //     }
    //     setActive(true)
    // }

    // function cancelInvitation() {
    //     setOverlayVisible(false);
    //     setOverlayCancelVisible(true)
    //     setNotifications('')
    //     setNotificationsType('')
    // }

    // function continueWithAction() {
    //     setOverlayVisible(true);
    //     setOverlayCancelVisible(false)
    // }

    function discardInviteUser() {
        setOverlayVisible(false);
        setOverlayCancelVisible(false)
        onClose()
    }

    return (
        <>

            <Overlay
                actions={[
                    {
                        action: function () { sendInvitation() },
                        title: t('Account.Users.InviteUser.Action'),
                        type: 'primary',
                        //type: 'ghost',
                        active: emailIsValid
                    },
                    {
                        action: function () { discardInviteUser() },
                        //action: function () { cancelInvitation() },
                        title: t('Common.Actions.Cancel'),
                        type: 'ghost'
                    }
                ]}
                active={active}
                allowCloseWithActions={false}
                centerContentVertically={false}
                error={false}
                errorTemplate="Something went wrong. Please try again later."
                height={null}
                loading={false}
                notifications={notifications}
                notificationsType={notificationsType}
                title={t('Account.Users.InviteUser.Title')}
                visible={overlayVisible}
                widthInColumns= {[12,10,8,8,8]}
            >
                <div>
                    <HorizontalFlow
                        maxItemsPerLine={2}
                        useBottomMargin={true}
                        useTopMargin={false}
                    >
                        <Input
                            active={true}
                            label={t('Account.Users.InviteUser.User.Email')}
                            loading={false}
                            setValue={setEmail}
                            placeholder={t('Account.Users.InviteUser.User.EmailPlaceholder')}
                            required={true}
                            requiredMessage={t('Account.Users.InviteUser.EmailRequiredMessage')}
                            showValidationError={showValidationError}
                            type="email"
                            validationRegExpString="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                            validationCallback={(valid, text) => { setEmailIsValid(valid); console.log('validation result:', valid, text) }}
                            value={email}
                            width="100%"
                        />
                    </HorizontalFlow>

                    <TextWithIcon
                        icon="info"
                        text={t('Account.Users.InviteUser.RoleSection.Description')}
                        active={true}
                        disableTextSelection={false}
                        wrap={true}
                    />

                    {/*

                    <CheckboxsView
                        title={t('Account.Users.InviteUser.RolesSection.Title')}
                        items={roles}
                    />
                    
                    */}

                </div>
            </Overlay>

            {/* 
            
            <CancelActionConfimation
                actions={[
                    {
                        action: function () { discardInviteUser() },
                        title: t('Common.Actions.Discard'),
                        type: 'primary'
                    },
                    {
                        action: function () { continueWithAction() },
                        title: t('Account.Users.InviteUser.ContinueInvitation'),
                        type: 'ghost'
                    }
                ]}
                loading={false}
                title={t('Account.Users.InviteUser.CancelTitle')}
                isVisible={overlayCancelVisible}
                notifications={notifications}
                notificationsType={notificationsType}
                question={t('Account.Users.InviteUser.CancelQuestion')}
            >

            </CancelActionConfimation>
            
            */}
        </>
    )
}