import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { getStoredPath, clearStoredPath } from '../../components/AuthenticationController';
import { useHistory } from "react-router";

const AuthCallback = () => {
  const { instance } = useMsal();
  const history = useHistory();

  useEffect(() => {
    instance.handleRedirectPromise().then(() => {
        debugger
        let path = getStoredPath()
        clearStoredPath()
        if(path){
            history.push(path)
        }
        history.push("/")
    });
  }, [instance]);

  return (
    <div>
      <p>Processing authentication...</p>
    </div>
  );
};

export default AuthCallback;
